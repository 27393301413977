.event-page {
  background: radial-gradient(
    53.54% 49.58% at 42.53% 21.3%,
    #3274c3 0.07%,
    #0e1e3f 100%
  );
  background-attachment: fixed;
  position: relative;

  @media only screen and (max-width: 900px) {
    background: #0e1e40;
    &.active-sort .event-main {
      z-index: 101;
    }

    &::after {
      content: '';
      position: fixed;
      width: 517.36px;
      height: 825.45px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        #2f6bb5 0%,
        rgba(14, 30, 64, 0) 100%
      );
      background-attachment: fixed;
      opacity: 0.5;
      transform: translateX(-50%) rotate(42.9deg);
      left: 50%;
      top: 0;
    }
  }

  #root {
    min-height: 100%;
    background: url(../../images/mocana/star-bg.png) 50% 34px no-repeat;
    background-attachment: fixed;

    @media only screen and (max-width: 900px) {
      background: url(../../images/mocana/star-mobile-bg.png) 50% 200px
        no-repeat;
      background-attachment: fixed;
    }
  }

  .mocana {
    &-wrapper {
      background: url(../../images/mocana/background-event.svg) 50% 0 no-repeat;

      @media only screen and (max-width: 767px) {
        background: url(../../images/mocana/background-event-mobile.svg) 50%
          64px no-repeat;
        // background-size: 100% 252px;
        background-size: 100%;
        position: relative;
        z-index: 1;
      }
    }

    &-main {
      display: flex;
      max-width: 1218px;
      margin: 0 auto;
      padding: 312px 12px 0;
      flex-wrap: nowrap;
      width: 100%;
      align-items: flex-start;
      position: relative;
      .title-event {
        position: absolute;
        width: 100%;
        height: 312px;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @media only screen and (max-width: 900px) {
        flex-wrap: wrap;
        padding: 284px 12px 90px;
        margin: 0 0 110px;
        .title-event {
          height: 348px;
          img {
            max-width: 197px;
          }
        }
      }
    }
  }
}

.event-bar-line {
  position: relative;
  padding-left: 32px;
  white-space: nowrap;

  @media only screen and (max-width: 1023px) {
    padding-left: 25px;
  }

  &:before {
    content: '';
    height: 24px;
    width: 1px;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);

    @media only screen and (max-width: 1023px) {
      height: 16px;
      left: 13px;
    }
  }
}

.event-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #fff;
  margin: 0 0 12px;

  @media only screen and (max-width: 900px) {
    font-size: 20px;
    line-height: 24px;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    margin: 0 0 4px;
  }

  &-wrapper {
    margin: 0 0 47px;

    @media only screen and (max-width: 900px) {
      margin: 0 0 20px;
    }
  }

  &-notify {
    display: flex;
    align-items: center;
    color: #fff;
    @media only screen and (max-width: 768px) {
      position: relative;
      .btn-share {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 5px;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        &:hover {
          position: absolute;
        }
        img {
          margin-right: 0 !important;
        }
        span {
          display: none;
        }
      }
    }

    @media only screen and (max-width: 1023px) {
      flex-wrap: wrap;
    }

    .btn-share {
      img {
        max-width: 20px;
        margin-right: 4px;
      }
      background: #fff;
      margin-left: 30px;
    }

    p {
      margin: 0;
    }
  }

  &-date {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    flex-wrap: nowrap;

    @media only screen and (max-width: 1023px) {
      margin: 0 0 10px !important;
      width: 100%;
    }

    @media only screen and (max-width: 900px) {
      font-size: 14px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.9);
    }
  }

  &-icon {
    margin: 0 5px 0 0;
    @media only screen and (max-width: 900px) {
      width: 16px;
      height: 16px;
    }
  }

  &-type {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    @extend .event-bar-line;

    @media only screen and (max-width: 900px) {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }

    span {
      padding: 8px 12px;
      background: rgba(165, 51, 255, 0.8);
      border-radius: 6px;
      min-height: 40px;
      display: block;

      @media only screen and (max-width: 900px) {
        border-radius: 4px;
        padding: 4px;
        min-height: 24px;
      }
    }

    .odyssey {
      background: rgba(21, 221, 125, 0.8);
    }
  }

  &-status {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.8);
    @extend .event-bar-line;

    @media only screen and (max-width: 1023px) {
      padding-left: 0;

      &:before {
        display: none;
      }
    }

    @media only screen and (max-width: 900px) {
      font-size: 14px;
      line-height: 16px;
    }

    .live {
      font-weight: 700;
      color: #fff;
      display: flex;
      align-items: center;
      &:before {
        content: '';
        display: inline-flex;
        width: 10px;
        height: 10px;
        background: #ff0000;
        border-radius: 5px;
        margin: 0 4px 0 0;

        @media only screen and (max-width: 1023px) {
          width: 6px;
          height: 6px;
        }
      }
    }

    .ended {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.event-section {
  margin: 0 0 18px;
  color: #fff;

  &-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0 0 10px;
    margin: 0 0 12px;

    @media only screen and (max-width: 900px) {
      font-size: 20px;
      line-height: 24px;
      padding: 0 0 3px;
    }
  }
  &-content {
    .note {
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 24px;
      font-size: 20px;
      @media only screen and (max-width: 900px) {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #fff;
      }
    }
  }
}

.event-reward {
  &-list {
    margin: 0 -12px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: 900px) {
      margin: 0 -11px;
    }
  }

  &-item {
    padding: 0 12px;
    margin: 0 0 24px;
    text-align: center;
    width: 157px;
    max-width: 33.33%;

    @media only screen and (max-width: 900px) {
      padding: 0 11px;
      width: 121px;
    }
  }

  &-img {
    font-size: 0;
    line-height: 0;
    margin: 0 0 12px;

    @media only screen and (max-width: 900px) {
      margin: 0 0 7px;
      max-width: 101px;
    }

    img {
      max-width: 100%;
    }
  }

  &-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    @media only screen and (max-width: 900px) {
      margin: 0 0 5px;
    }
  }

  &-subtitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin: 0;

    @media only screen and (max-width: 900px) {
      margin: 0 -2px;
      text-align: center;
    }
  }
}

.event-board {
  &-wrapper {
    border-radius: 8px;
    // overflow: hidden;
    margin: 0 0 23px;
  }

  &-header {
    background: #00d1ff url(../../images/mocana/board-title-bg.png) 0 0
      no-repeat;
    background-size: cover;
    min-height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 7px 20px;
    color: #00327c;
    border-radius: 8px 8px 0 0;
    @media only screen and (max-width: 520px) {
      background: #00d1ff url(../../images/mocana/board-title-bg-mb.png)
        no-repeat;
      background-size: cover;
    }
    @media only screen and (max-width: 900px) {
      min-height: 48px;
      padding: 0 12px;
    }
  }

  &-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    @media only screen and (max-width: 900px) {
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 3px;
    }
  }

  &-subtitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.8);
    margin: 0;
    text-align: center;
  }

  &-main {
    border-radius: 0 0 8px 8px;
    overflow: hidden;
  }

  &-item {
    background: rgba(0, 31, 67, 0.8);
    display: flex;
    flex-wrap: nowrap;
    margin: 2px 0 0;

    &-header {
      background: #00183e;
      width: 232px;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #ffec00;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 8px 20px;

      @media only screen and (max-width: 1023px) {
        width: 150px;
        padding: 8px 12px;
      }

      @media only screen and (max-width: 900px) {
        font-size: 14px;
        line-height: 16px;
        width: 72px;
        padding: 8px 3px;
      }
    }

    &-main {
      flex: 1;
    }

    &-title {
      span {
        font-size: 24px;

        @media only screen and (max-width: 900px) {
          font-size: 18px;
          line-height: 20px;
        }
      }
      .sm {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        display: block;
      }
    }

    &-hr {
      @media only screen and (max-width: 900px) {
        display: block;
      }
    }

    &-subtitle {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #fff;
      margin: 5px 0 10px;
      text-align: center;

      @media only screen and (max-width: 900px) {
        display: none;
      }
    }

    &-rw {
      &-list {
        margin: 0;
        flex-direction: row;
        display: flex;
        align-items: flex-start;
        padding: 0 25px;
        flex-wrap: wrap;

        @media only screen and (max-width: 1023px) {
          padding: 0 10px;
        }

        @media only screen and (max-width: 900px) {
          padding: 0;
          margin: 0;
          justify-content: space-around;
        }

        &.center {
          justify-content: center;
        }
      }
      &-item {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 33.33%;
        text-align: center;
        padding: 8px 5px;

        @media only screen and (max-width: 900px) {
          width: auto;
          padding: 8px 13px;
        }
      }
      // &-img {
      // }
      // &-name {
      // }
      &-subtitle {
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        margin: 0;
      }
    }
  }

  &-badge {
    display: none;

    @media only screen and (max-width: 900px) {
      display: unset;
    }
  }
}

.event-highscore-board {
  .event-board {
    &-item {
      &:last-child {
        .event-board-item-title {
          @media only screen and (max-width: 900px) {
            line-height: 24px;
          }
        }
      }
    }

    &-item-title {
      br {
        display: none;

        @media only screen and (max-width: 900px) {
          display: block;
        }
      }
    }

    &-item-rw-item {
      @media only screen and (max-width: 374px) {
        max-width: 100%;
      }
    }
  }
}

.event-totalscore-board {
  .event-board {
    &-item-title {
      span {
        font-size: 20px;
        line-height: 24px;

        @media only screen and (max-width: 900px) {
          font-size: 18px;
          line-height: 20px;
        }
      }
      br {
        display: none;

        @media only screen and (max-width: 900px) {
          display: block;
        }
      }
    }
  }
}

.event-tribe-board {
  .event-board {
    &-item-header {
      @media only screen and (max-width: 900px) {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
    }

    &-item-title {
      .sm {
        @media only screen and (max-width: 900px) {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

.event-reward-detail {
  &-note {
    padding: 5px 0 7px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.8);

    @media only screen and (max-width: 900px) {
      display: none;
    }
  }
  &-note-mobile {
    display: none;

    @media only screen and (max-width: 900px) {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.event-requirement {
  p {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 15px;

    @media only screen and (max-width: 900px) {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 9px;
    }
  }

  ul {
    margin: 0 0 25px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
    line-height: 20px;

    @media only screen and (max-width: 900px) {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
    }

    li {
      position: relative;
      padding: 0 0 0 23px;
      margin: 0 0 5px;
      @media only screen and (max-width: 900px) {
        margin: 0;
        padding: 0 0 0 22px;
      }

      &:before {
        content: '';
        width: 2px;
        height: 2px;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 2px;
        display: inline-flex;
        position: absolute;
        left: 6px;
        top: 8px;

        @media only screen and (max-width: 900px) {
          width: 3px;
          height: 3px;
          left: 9px;
          top: 10px;
          border-radius: 3px;
        }
      }
    }
  }

  .note {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
    line-height: 20px;

    @media only screen and (max-width: 900px) {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
    }
  }
}

.event-ht-register {
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 50px;

  @media only screen and (max-width: 900px) {
    font-size: 16px;
    line-height: 20px;
  }

  ol {
    margin: 18px 0 25px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
    counter-reset: list-counter;
    list-style: none;
    line-height: 20px;

    @media only screen and (max-width: 900px) {
      margin: 18px 0 22px;
    }

    li {
      position: relative;
      padding: 0 0 0 50px;
      margin: 0 0 15px;
      counter-increment: list-counter;
      list-style: none;

      @media only screen and (max-width: 900px) {
        margin: 0 0 22px;
      }

      &:before {
        content: counter(list-counter);
        width: 36px;
        height: 36px;
        background: #fff;
        border-radius: 18px;
        display: flex;
        position: absolute;
        left: 0px;
        top: -6px;
        color: #000;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        padding: 5px;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #fff;
      margin: 0 0 10px;

      @media only screen and (max-width: 900px) {
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 8px;
      }
    }

    p {
      &:last-child {
        margin: 0;
      }
    }

    a {
      color: rgba(255, 255, 255, 0.8);
      text-decoration: none;
    }

    ~ p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.mocana-event {
  &-wrapper {
    // max-width: 1280px;
    background: url('../../images/mocana/event-block-bg.png') 50% 0 no-repeat;
    max-width: 1204px;
    padding: 20px 77px 20px 48px;
    margin: 22px auto 12px;
    min-height: 290px;
    background-size: cover;
    border-radius: 18px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @media only screen and (max-width: 991px) {
      padding: 20px 10px;
      background: url('../../images/mocana/event-block-mobile-bg.png') 50% 0
        no-repeat;
      background-size: cover;
      border-radius: 0;
      min-height: 252px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
    }

    .btn-view-experience {
      margin: 0 0 20px;
      @media only screen and (max-width: 991px) {
        margin: 0;
      }
    }
  }

  &-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #fff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0 0 8px;

    @media only screen and (max-width: 991px) {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
      margin: 0;
    }

    &-icon {
      margin: 0 5px 0 0;
      @media only screen and (max-width: 991px) {
        width: 16px;
        height: 16px;
      }
    }
  }

  &-title-date {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.8);
    margin: 0;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 991px) {
      font-size: 14px;
      line-height: 16px;
      justify-content: center;
      margin: 0 0 20px;
    }
  }
}
