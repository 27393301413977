.campaign-wrapper {
  background-color: #0080ce;
  min-height: 100vh;
  padding-bottom: 62px;

  .campaign-header-bg {
    background-image: url(../../images/campaign/partnership_banner.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    isolation: isolate;
  }

  .list-tabs.main-tabs-campaign {
    position: relative;
    padding-top: 12px;
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      z-index: -1;
      background: linear-gradient(
        to bottom,
        rgba(0, 128, 206, 1),
        rgba(0, 128, 206, 0)
      );
      height: 110px;
    }
    ul {
      padding: 0;
      margin: 0;
      margin-bottom: 36px;
      display: flex;
      width: max-content;
      border-bottom: 0;
      gap: 0;
      border-radius: 8px;
      overflow: hidden;
      border: 2px solid #000000;
      box-shadow: 3px 3px 0px #000000;
      margin-top: 12px;
      li {
        padding: 0;
        &:nth-child(2) {
          border-left: 2px solid #000000;
        }
        button {
          background: transparent;
          padding: 6px 47px;
          border: 0;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #333333;
          filter: unset;
          bottom: 0;
          background: #ffffff;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          margin: 0;
          &.active {
            font-weight: 800;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: #000000;
            background: #ffcf00;
            bottom: 0;
          }
        }
      }
    }
  }

  .campaign-header-wrapper {
    max-width: 1152px;
    margin: 0 auto;
    padding-top: 144px;
    .campaign-title {
      font-weight: 700;
      font-size: 60px;
      line-height: 75px;
      text-align: left;
      color: #ffffff;
    }
    .category-tabs-campaign {
      ul {
        margin-bottom: 0px;
      }
      button {
        padding: 14px 24px;
        bottom: -6px;
      }
      .active {
        padding: 16px 24px;
        bottom: -2px;
      }
      // TODO: remove this when Odyssey works
      // temporarily disable the button
      ul {
        li:nth-child(2) {
          button {
            background-color: #bebebe !important;
          }
        }
      }
    }

    .selected {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 400px;
      .loading-icon {
        width: 60px;
        height: 60px;
      }
    }
  }
  .campaign-content-wrapper {
    max-width: 1152px;
    margin: 0 auto;
    .main-tabs-campaign-mobile {
      display: none;
    }
    .tab-content {
      .campaign-list-wrapper {
        width: 100%;
        .campaign-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
          margin: 0;
          &__item {
            width: 33%;
            max-width: 364px;
            box-shadow: 3px 3px 0px #000000;
            border-radius: 20px;
            border: 2px solid #000000;
            background: #ffffff;
            position: relative;
            &--header {
              position: relative;
              display: flex;
              height: 91px;
              background-image: url('../../images/campaign/campaign-item-header.svg');
              background-repeat: no-repeat;
              background-size: cover;
              border-radius: 18px 18px 0 0;
              .line-bottom {
                position: absolute;
                left: 0;
                bottom: -10px;
                width: 100%;
                img {
                  width: 100%;
                }
              }
              .arc-thumbnail {
                width: 106px;
                height: 106px;
                border: 4px solid #ffffff;
                border-radius: 100%;
                background: #231973;
                position: absolute;
                left: calc((100% - 106px) / 2);
                bottom: -41px;
                img {
                  display: block;
                  width: 106px;
                  height: 106px;
                  border: 4px solid #ffffff;
                  border-radius: 100%;
                  position: absolute;
                  left: inherit;
                  bottom: -2px;
                }
              }
              &--giveaway {
                background-image: url('../../images/campaign/campaign-giveaway-item-header.svg');
                margin: -2px 0;
              }
            }
            &--content {
              padding: 54px 17px 20px;
              background: #ffffff;
              border-radius: 0px 0px 20px 20px;
              display: flex;
              flex-direction: column;
              gap: 2px;

              &-title {
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
                margin-bottom: 0;
                text-align: center;
              }
              &-live-countdown {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 0;
                span {
                  font-weight: 800;
                  font-size: 18px;
                  line-height: 24px;
                  letter-spacing: 0.02em;
                  color: #0080ce;
                }
              }
              &-ended {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #000000;
                margin-bottom: 0;
                text-align: center;
              }
              &-reward {
                &--title {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  color: #333333;
                  margin-bottom: 2px;
                  text-align: center;
                }
                &--list {
                  margin: 0;
                  display: flex;
                  gap: 9px;
                  justify-content: center;
                  &-item {
                    width: 58px;
                    height: 58px;
                    border: 1.8px solid #000000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                      width: 100%;
                    }
                    &.last-item {
                      span {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        color: #000000;
                      }
                    }
                  }
                }
              }
            }
            &--footer {
              &-not-registered {
                padding: 13px 0 13px;
                border-radius: 0px 0px 17px 17px;
                display: flex;
                justify-content: center;
                border-top: 1px solid #000000;
                background: #ffcf00;
                a {
                  font-weight: 800;
                  font-size: 18px;
                  line-height: 24px;
                  letter-spacing: 0.02em;
                  color: #000000;
                  text-decoration: none;
                }
              }
              &-registered {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 9px 24px;
                border-radius: 0px 0px 17px 17px;
                background: #ededed;
                &--moca {
                  display: flex;
                  align-items: center;
                  gap: 9px;
                  &-thumbnail {
                    width: 32px;
                    height: 32px;
                    border-radius: 100%;
                    border: 2px solid #000000;
                    overflow: hidden;
                    img {
                      width: 100%;
                    }
                  }
                  &-id {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    color: #000000;
                  }
                }
                &--status {
                  &-joined,
                  &-won {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    span {
                      font-weight: 500;
                      font-size: 16px;
                      line-height: 20px;
                      color: #000000;
                    }
                  }
                }
              }
              &-didnt-register {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 11px 0px 13px;
                border-radius: 0px 0px 17px 17px;
                background: #ededed;
                gap: 7px;
                span {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 20px;
                  color: #000000;
                  opacity: 0.5;
                }
              }
            }
            &--live {
              display: flex;
              align-items: center;
              gap: 8px;
              padding: 6px 8px;
              background: #000000;
              border-radius: 0px 12px;
              position: absolute;
              left: -2px;
              top: -2px;

              .circle {
                width: 8px;
                height: 8px;
                border-radius: 100%;
                background: red;
              }
              span {
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                text-align: right;
                color: #ffffff;
              }
            }
            &--category {
              &-odyssey {
                position: absolute;
                border-radius: 6px;
                padding: 8px 10px;
                top: 14px;
                right: 6px;
                background-color: rgba($color: #009f10, $alpha: 0.6);
                span {
                  font-weight: 700;
                  color: #ffffff;
                }
              }
              &-giveaway {
                position: absolute;
                border-radius: 6px;
                padding: 8px 10px;
                top: 14px;
                right: 6px;
                background-color: rgba($color: #a533ff, $alpha: 0.6);
                span {
                  font-weight: 700;
                  color: #ffffff;
                }
              }
            }
            &--giveaway {
              &--header {
                position: relative;
                display: flex;
                height: 91px;
                background-image: url('../../images/campaign/campaign-giveaway-item-header.svg');
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 18px 18px 0 0;
              }
              &--content {
                padding: 82px 17px 71px;
              }
            }
          }
        }
        .campaign_pagination {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 1.5rem auto;
          gap: 16px;
          &--prev,
          &--next {
            img {
              transform: rotate(90deg);
              width: 22px;
              margin-top: 3px;
            }
          }
          &--next img {
            transform: rotate(-90deg) !important;
          }
          select {
            font-family: inherit;
            font-size: 1.4rem;
            font-weight: 800;
            padding: 8px 28px;
            width: 100px;
            border-radius: 30px;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-image: url(../../images/arrow-down.svg);

            background-repeat: no-repeat;
            background-position: right 0.8em top 50%;
            background-size: 1em auto;
            box-shadow: 4px 4px 0px #000000;
            background-color: #f8d748;
          }
          &--select {
            font-family: inherit;
            font-size: 1.4rem;
            font-weight: 800;
            padding: 10px 28px;
            width: 110px;
            border-radius: 30px;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-image: url(../../images/arrow-down.svg);
            background-repeat: no-repeat;
            background-position: right 0.8em top 50%;
            background-size: 1em auto;
            box-shadow: 4px 4px 0px #000000;
            border: 2px solid #000000;
            background-color: #f8d748;
            position: relative;
            &--option {
              background: #f8d748;
              border: 1px solid #000000;
              box-shadow: 4px 4px 0px #000000;
              border-radius: 8px;
              position: absolute;
              width: 100%;
              bottom: 110%;
              left: 0;
              ul {
                margin: 0;
                padding: 0;
                max-height: 246px;
                overflow: scroll;
                &::-webkit-scrollbar {
                  background: none;
                  height: 6px;
                  width: 7px;
                }
                &::-webkit-scrollbar-corner {
                  background: none;
                }
                &::-webkit-scrollbar-thumb {
                  background: rgba(136, 111, 85, 0.5);
                  border-radius: 100px;
                }
                li {
                  padding: 6px 16px;
                  &:hover {
                    background: #ffa800;
                  }
                }
              }
              .last-item {
                border-top: 1px solid rgba(0, 0, 0, 0.5);
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                padding: 6px 16px;
                &:hover {
                  background: #ffa800;
                }
              }
            }
          }

          &--label {
            color: #fff;
            font-weight: 600;
            font-size: 20px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1190px) {
    padding: 0 16px 62px;
    .campaign-content-wrapper {
      .tab-content {
        padding: 0;
        .campaign-list-wrapper {
          .campaign-list {
            gap: 16px;
            &__item {
              width: 32%;
              &--content {
                &-reward {
                  &--list {
                    &-item {
                      width: 20%;
                      height: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .campaign-header-wrapper {
      background-position-x: -290px;
    }
    .campaign-content-wrapper {
      .tab-content {
        padding: 0;
        .campaign-list-wrapper {
          .campaign-list {
            justify-content: space-between;
            gap: 28px;
            &__item {
              width: 48%;
              max-width: unset;
              &--content {
                &-reward {
                  &--list {
                    &-item {
                      width: 20%;
                      height: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .campaign-header-wrapper {
      padding: 88px 0 14px;
      background-position-x: -510px;
    }
    .campaign-content-wrapper {
      .tab-content {
        .campaign-list-wrapper {
          .campaign-list {
            gap: 16px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 576px) {
    padding: 0;
    .campaign-header-bg {
      background-size: 110% 125%;
    }
    .campaign-header-wrapper {
      padding: 88px 10px 14px;
      .campaign-title {
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        color: #ffffff;
      }

      .category-tabs-campaign {
        button {
          width: 100%;
          padding: 6px 30px;
        }
        .active {
          padding: 8px 30px;
        }
      }
      .main-tabs-campaign-desktop {
        display: none;
      }
    }

    .campaign-content-wrapper {
      padding: 0 10px;
      .main-tabs-campaign-mobile {
        display: block;
        padding-top: 0px;
        ul {
          li {
            button {
              padding: 6px 41px;
            }
          }
        }
      }
      .tab-content {
        padding: 0;
        .campaign-list-wrapper {
          .campaign-list {
            justify-content: space-between;
            gap: 20px;
            &__item {
              width: 100%;
              max-width: unset;
              border: 3px solid #000000;
              &--live {
                left: -3px;
                top: -3px;
              }
              &--content {
                &-reward {
                  &--list {
                    &-item {
                      width: 20%;
                      height: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
