@media only screen and (max-width: 767px) {
  main.user-board {
    position: unset;
  }
}
.landing-header-wrapper {
  background-image: url(../../images/user-board/bg-color.svg);
  padding: 100px 120px 24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #1c96da;
  background-position-x: 50%;
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    background-position-x: 50%;
  }
  @media only screen and (max-width: 767px) {
    background-image: url(../../images/user-board/bg-color-mobile.svg);
    padding: 94px 0px 16px 16px;
  }
  .landing-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #ffffff;
    max-width: 1180px;
    margin: 0 auto;
    margin-bottom: 13px;
    @media only screen and (max-width: 767px) {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
    }
  }
  .landing-content {
    max-width: 1180px;
    margin: 0 auto;
    display: flex;
    gap: 50px;
    .landing-content-item {
      display: flex;
      background: transparent;
      .item {
        display: flex;
        align-items: center;
        gap: 8px;
        .item-icon {
          width: 37px;
          height: 37px;
        }
        .item-content {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .item-content-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: #ffffff;
            margin: 0;
          }
          .item-content-quantity {
            font-weight: 800;
            font-size: 26px;
            line-height: 24px;
            color: #ffffff;
            margin: 0;
          }
          .item-content-quantity-more {
            font-weight: 800;
            font-size: 26px;
            line-height: 24px;
            color: #ffffff;
            margin: 0;
          }
        }
        &:last-child {
          padding-left: 0px;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 1px;
            height: 100%;
            background: rgba(255, 255, 255, 0.5);
          }
        }
        &:first-child {
          padding-right: 0px;
          &:before {
            display: none;
          }
        }
      }
    }
    @media only screen and (max-width: 767px) {
      .landing-content-item {
        .item {
          &-content {
            &-title {
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
            }
            &-quantity {
              font-weight: 800;
              font-size: 26px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
.landing-content-wrapper {
  background: #0080ce;
  .nav-tabs {
    background: #fcfcfc;
    ul {
      margin: 0;
      display: flex;
      justify-content: center;
      gap: 60px;
      li {
        padding: 16px;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #3e3e3e;
        position: relative;
        &.active {
          color: #141414;
          &::after {
            content: '';
            position: absolute;
            height: 4px;
            width: 100%;
            background: #141414;
            left: 0;
            bottom: 0;
          }
        }
      }
    }
  }
  > div.tab-item {
    display: block;
    min-height: 85vh;
  }
  .tab-item {
    display: none;
    &.selected {
      display: block;
    }
    .moca-wrapper {
      padding: 24px 0;

      .moca-search-filter {
        display: flex;
        gap: 8px;
      }
      .mocana-filter {
        position: relative;
        .mocana-button-filter {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 10;
          margin-left: 1rem;
          button {
            font-weight: 700;
            font-size: 18px;
            line-height: 32px;
            color: #000000;
            background: #ffcf00;
            border: 2px solid #000000;
            box-shadow: 6px 6px 0px #000000;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 24px;
            &:hover {
              background-color: #ffb800;
              box-shadow: none;
              position: relative;
              bottom: -7px;
            }
          }
        }
      }
      .moca-search {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #000;
        padding: 0px 16px;
        border-radius: 30px;
        width: 100%;
        background-color: #e7e7e7;
        position: relative;
        height: 40px;
        img {
          max-width: 30px;
        }
        button {
          border: 0;
          background: transparent;
          right: 6px;
          position: absolute;
        }
        input {
          width: 100%;
          font-size: 20px;
          line-height: 24px;
          padding: 0 0 0 12px;
          background: transparent;
          border: transparent;
          font-family: 'Montserrat';
          font-weight: bold;
          &::placeholder {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #888888;
          }
          &:focus-visible {
            outline: none;
          }
        }
        @media only screen and (max-width: 767px) {
          padding: 5px 11px;
          img {
            max-width: 23px;
          }
          input {
            font-size: 12px;
            line-height: 16px;
            padding: 0 0 0 4px;
            &::placeholder {
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: rgba(0, 0, 0, 0.3);
            }
          }
        }
      }
      .moca-filter,
      .moca-sort {
        position: relative;
        border: 0;
        background: transparent;
        width: 39px;
        height: 39px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffcf00;
        border: 2px solid #000000;
        border-radius: 100px;
        box-shadow: 3px 3px 0px #000000;
        > button {
          background: transparent;
          border: 0;
        }
      }
      .moca-content {
        .loading {
          margin: 2rem;
          text-align: center;
          img {
            max-width: 100px;
          }
        }
        &-header-wrapper {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          max-width: 1180px;
          margin: 0 auto;
          margin-bottom: 21px;
          gap: 16px;
          @media only screen and (max-width: 767px) {
            flex-direction: column-reverse;
          }
          @media only screen and (max-width: 1024px) {
            padding: 0 16px;
          }
        }
        &-header {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
        }
        .staking-period {
          display: flex;
          gap: 7px;
          align-items: center;
          max-width: 1180px;
          margin: 0 auto;
          margin-bottom: 6px;
          &-title {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #ffffff;
            margin-bottom: 0;
          }
          &-countdown {
            display: flex;
            gap: 3px;
            align-items: center;
            @media only screen and (max-width: 767px) {
              img {
                max-width: 21px;
              }
            }
            .time {
              font-weight: 800;
              font-size: 24px;
              line-height: 24px;
              letter-spacing: 0.02em;
              color: #ffcf00;
            }
          }
          @media only screen and (max-width: 900px) {
            justify-content: space-between;
            &-title {
              font-size: 16px;
              line-height: 24px;
            }
            &-countdown {
              .time {
                font-size: 16px !important;
                line-height: 24px;
              }
            }
          }
          @media only screen and (max-width: 1024px) {
            white-space: nowrap;
            padding: 0 16px;
          }
        }
        .staking-disclaimer {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #ffffff;
          max-width: 1180px;
          margin: 0 auto;
          margin-bottom: 21px;
          @media only screen and (max-width: 1024px) {
            padding: 0 16px;
          }
        }
        .nav-tabs {
          background: transparent;
          ul {
            gap: 0px;
            background: #f2f2f2;
            box-shadow: 4px 4px 0px #000000;
            border-radius: 100px;
            border: 2px solid #000000;
            li {
              min-width: 178px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 600;
              font-size: 18px;
              line-height: 24px;
              color: #000000;
              padding: 8px 16px;
              gap: 5px;
              &:last-child {
                border-top-right-radius: 100px;
                border-bottom-right-radius: 100px;
              }
              &:first-child {
                border-top-left-radius: 100px;
                border-bottom-left-radius: 100px;
              }
              &.active {
                background: #ffcf00;
                font-weight: 800;
              }
              &.active::after {
                display: none;
              }
              &:not(:last-child) {
                border-right: 2px solid #000000;
              }
              .moca-tooltip {
                &-label {
                  svg {
                    bottom: unset;
                  }
                }
              }
            }
          }
          @media only screen and (max-width: 991px) {
            ul {
              li {
                min-width: unset;
              }
            }
          }
          @media only screen and (max-width: 767px) {
            ul {
              border-radius: 16px;
              li {
                padding: 8px 26px;
                min-width: unset;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #333333;
                &:last-child {
                  padding: 8px;
                  border-top-right-radius: 16px;
                  border-bottom-right-radius: 16px;
                }
                &:first-child {
                  border-top-left-radius: 16px;
                  border-bottom-left-radius: 16px;
                }
                &.active {
                  background: #ffcf00;
                  font-weight: 700;
                }
              }
            }
          }
        }
        .list-moca {
          display: flex;
          gap: 35px;
          flex-wrap: wrap;
          max-width: 1180px;
          margin: 0 auto;
          @media only screen and (max-width: 767px) {
            gap: 15px;
          }
          @media only screen and (max-width: 1024px) {
            padding: 0 16px;
          }
          .list-moca-item {
            width: 24%;
            max-width: 268px;
            position: relative;
            background: #e9e9e9;
            border: 4px solid #000000;
            box-shadow: 8px 8px 0px #000000;

            @media only screen and (min-width: 901px) and (max-width: 1200px) {
              width: 22%;
              max-width: unset;
            }
            @media only screen and (min-width: 768px) and (max-width: 900px) {
              width: 30%;
              max-width: unset;
            }
            @media only screen and (max-width: 767px) {
              width: 47%;
              max-width: unset;
            }

            .thumbnail {
              width: 100%;
            }
            .moca-item-info {
              padding: 8px 16px;
              background: rgba(35, 35, 35, 0.72);
              position: absolute;
              bottom: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              @media only screen and (max-width: 767px) {
                padding: 0px 2px;
              }
              .info-id {
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                color: #ececec;
                margin-bottom: 0;
                @media only screen and (max-width: 767px) {
                  font-size: 16px;
                  line-height: 24px;
                }
              }
              .info-xp {
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                color: #ececec;
                margin-bottom: 0;
                @media only screen and (max-width: 767px) {
                  font-size: 16px;
                  line-height: 24px;
                }
              }
            }
            .staked {
              margin-bottom: 0;
              position: absolute;
              top: -12px;
              right: -12px;
              background: #000000;
              border: 3px solid #000000;
              border-bottom-left-radius: 6px;
              padding: 8px 16px;
              font-weight: 700;
              font-size: 20px;
              line-height: 24px;
              color: #2cff74;
              @media only screen and (max-width: 767px) {
                font-size: 16px;
                line-height: 20px;
                padding: 2px 12px;
                top: -8px;
                right: -8px;
              }
            }
            .rare-badge {
              position: absolute;
              bottom: 69px;
              left: -16px;
              width: 52px;
              height: 52px;
              border-radius: 100px;
              background: #fad722;
              img {
                width: 100%;
              }
              @media only screen and (max-width: 767px) {
                bottom: 28px;
                left: -12px;
                width: 32px;
                height: 32px;
              }
            }
            &:hover {
              .view-detail {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .view-detail {
              display: none;
              position: absolute;
              width: 100%;
              height: 100%;
              bottom: 0;
              left: 0;
              background: rgba(0, 0, 0, 0.6);
              .btn-view-detail {
                font-weight: 700;
                font-size: 18px;
                line-height: 32px;
                color: #000000;
                background: #ffffff;
                border: 2px solid #000000;
                box-shadow: 6px 6px 0px #000000;
                border-radius: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 24px;
                &:hover {
                  background-color: #ffffff;
                  box-shadow: none;
                  position: relative;
                  bottom: -7px;
                }
              }
              @media only screen and (max-width: 767px) {
                .btn-view-detail {
                  font-size: 12px;
                  line-height: 14px;
                  padding: 12px 24px;
                }
              }
            }
          }
          .modal-wrapper-parent {
            @media only screen and (max-width: 900px) {
              position: fixed;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              margin-top: 64px;
              > div {
                height: calc(100% - 73px);
                overflow: auto;
                position: relative;
                .moca-table-tooltip-modal {
                  position: absolute;
                  top: 9px;
                  .toggle-button {
                    top: -6px;
                  }
                }
              }
            }
          }
        }
        .invalid-moca {
          padding: 34px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: #1c96da;
          a {
            text-decoration: none;
          }
          > img {
            margin-bottom: 9px;
          }
          .invalid-title {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            color: #ffffff;
            margin-bottom: 8px;
          }
          .invalid-sub-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: rgba(255, 255, 255, 0.8);
            margin-bottom: 12px;
          }
          .btn-get-moca {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 4px;
            padding: 13px 24px;
            background: #ffcf00;
            border: 3px solid #000000;
            box-shadow: 3px 3px 0px #000000;
            border-radius: 100px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 32px;
            color: #000000;
            text-decoration: none;
            height: 50px;
            box-sizing: border-box;
            margin-top: 28px;
            img {
              width: 24px;
              height: 24px;
            }
            &:hover {
              bottom: -3px;
              right: -3px;
              box-shadow: none;
              position: relative;
              background-color: #ffb800;
            }
          }
        }
        .moca-table-tooltip-modal {
          visibility: visible;
          background-image: url(../../images/user-board/bg-modal-color.svg);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          min-height: 629px;
          position: fixed;
          top: calc((100% - 629px) / 2);
          left: calc(calc(100% - 980px) / 2);
          width: 100%;
          max-width: 980px;
          z-index: 1110;
          @media only screen and (max-width: 992px) {
            background-image: url(../../images/user-board/bg-color-modal-mobile.svg);
            top: calc(calc(100% - 563px) / 2);
            left: calc(calc(100% - 349px) / 2);
            width: 100%;
            max-width: 349px;
            min-height: 563px;
          }
          .toggle-button {
            position: absolute;
            top: -3px;
            right: -1px;
            width: 55px;
            height: 55px;
            border-radius: 50%;
            border: 3px solid #000000;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f8d748;
            z-index: 100;
          }
          .tooltip-content {
            display: flex;
            width: 100%;
            height: 100%;
            position: absolute;
            @media only screen and (max-width: 992px) {
              flex-direction: column;
            }
            .left-block {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              max-width: 316px;
              .image-moca {
                width: 100%;
                max-width: 245px;
                border: 4px solid #000000;
                box-shadow: 8px 8px 0px #000000;
                display: flex;
                img {
                  width: 100%;
                }
              }
              .moca-id {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: rgba(255, 255, 255, 0.8);
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;
                margin-bottom: 14px;
                width: 100%;
                padding-left: 36px;
              }
              .title-xp {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: rgba(255, 255, 255, 0.8);
                margin-top: 16px;
                margin-bottom: 0;
                width: 100%;
                padding-left: 36px;
              }
              .season {
                padding-left: 36px;
                margin-top: 4px;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                color: rgba(255, 255, 255, 0.8);
                margin-bottom: 15px;
                width: 100%;
              }
              .xp-value {
                font-weight: 700;
                font-size: 40px;
                line-height: 48px;
                color: #ffffff;
                margin-bottom: 0;
                width: 100%;
                padding-left: 36px;
              }
              .moca-info-mobile {
                display: none;
              }
              @media only screen and (max-width: 992px) {
                flex-direction: row;
                padding: 24px 16px 11px 24px;
                gap: 14px;
                flex: unset;
                max-width: unset;
                .moca-info-mobile {
                  display: block;
                  &-id {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    color: rgba(255, 255, 255, 0.8);
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    margin-bottom: 5px;
                  }
                  &-title-xp {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    color: rgba(255, 255, 255, 0.8);
                    margin-bottom: 3px;
                  }
                  &-season {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    color: rgba(255, 255, 255, 0.8);
                    margin-bottom: 2px;
                  }
                  &-xp-value {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 32px;
                    color: #ffffff;
                    margin-bottom: 0;
                  }
                }
                .moca-id,
                .title-xp,
                .xp-value,
                .season {
                  display: none;
                }
                .image-moca {
                  max-width: 117px;
                  border: 3.4px solid #000000;
                  box-shadow: 4px 4px 0px #000000;
                }
              }
            }
            .info-moca {
              width: 100%;
              flex: 2;
              padding: 28px 0px;
              padding-right: 14px;
              position: relative;
              .tab-item {
                display: none;
                &.moca-info-detail-wrapper {
                  padding: 29px;
                  .moca-info-detail {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    .row {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      &:not(:last-child) {
                        padding-bottom: 8px;
                        border-bottom: 1px solid #0080ce;
                      }
                      .staking-period-countdown {
                        img {
                          display: none;
                        }
                        .time {
                          font-weight: 600;
                          font-size: 20px;
                          line-height: 24px;
                          text-align: right;
                          color: #ffffff;
                        }
                      }
                      .title {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                        color: #ffffff;
                      }
                      .description {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                        text-align: right;
                        color: #ffffff;
                        display: flex;
                        align-items: center;
                        gap: 2px;
                        &.text-color {
                          color: #0eb500;
                        }
                        &.text-color-red {
                          color: red;
                        }
                        > a {
                          padding: 4px 8px 4px 12px;
                          height: 28px;
                          background: #ffcf00;
                          border: 1px solid #000000;
                          border-radius: 100px;
                          font-size: 16px;
                          line-height: 20px;
                          text-align: center;
                          color: #000000;
                          text-decoration: none;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          &:after {
                            background-image: url('../../images/arrow-down.svg');
                            background-position-y: -1px;
                            content: '';
                            display: block;
                            height: 15px;
                            width: 18px;
                            background-size: 100%;
                            transform: rotate(270deg);
                          }
                        }
                      }
                    }
                    .btn-experiences {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding: 13px 24px;
                      background: #ffcf00;
                      border: 3px solid #000000;
                      box-shadow: 3px 3px 0px #000000;
                      border-radius: 100px;
                      font-family: 'Montserrat';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 18px;
                      line-height: 24px;
                      color: #000000;
                      text-decoration: none;
                      height: 50px;
                      box-sizing: border-box;
                      margin: 0 auto;
                      margin-top: 44px;
                      &:hover {
                        bottom: -3px;
                        right: -3px;
                        box-shadow: none;
                        position: relative;
                        background-color: #ffb800;
                      }
                    }
                  }
                  @media only screen and (max-width: 992px) {
                    padding: 16px;
                    padding-top: 21px;
                    .moca-info-detail {
                      padding-top: 0;
                      gap: unset;
                      .row {
                        padding: 12px 0 8px;
                        &:not(:last-child) {
                          border-bottom: 1px solid #0080ce;
                        }
                        .title {
                          max-width: 140px;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 16px;
                          color: #ffffff;
                        }
                        .description {
                          font-weight: 600;
                          font-size: 16px;
                          line-height: 20px;
                          color: #ffffff;
                          a {
                            filter: drop-shadow(3px 3px 0px #000000);
                          }
                        }
                      }
                    }
                  }
                }
                &.selected {
                  display: block;
                }
              }
              .nav-tabs {
                padding: 0 24px 11px;
                border-bottom: 2px solid #000000;
                ul {
                  box-shadow: unset;
                  border: 2px solid #000000;
                  background: transparent;
                  border-radius: 0;
                  filter: drop-shadow(3px 3px 0px #000000);
                  height: 40px;
                  border-radius: 12px;
                  width: max-content;
                  li {
                    background: #ffffff;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    color: #333333;
                    padding: 18px 20px;
                    border-bottom: 0;
                    min-width: 225px;
                    &:first-child {
                      border-right: 2px solid #000000;
                      border-top-left-radius: 10px;
                      border-bottom-left-radius: 10px;
                    }
                    &:last-child {
                      border-top-right-radius: 10px;
                      border-bottom-right-radius: 10px;
                    }
                    &.active {
                      background: #ffcf00;
                      font-weight: 600;
                      font-size: 20px;
                      line-height: 24px;
                      color: #000000;
                    }
                    @media only screen and (max-width: 992px) {
                      font-weight: 700;
                      font-size: 15px !important;
                      line-height: 24px;
                      padding: 5px 10px;
                      &.active {
                        font-size: 14px;
                        line-height: 24px;
                      }
                    }
                  }
                }
              }
              .moca-id {
                font-weight: 700;
                font-size: 45px;
                line-height: 48px;
                color: #000000;
                display: flex;
                align-items: center;
                gap: 15px;
                margin-bottom: 24px;
              }
              .history-tab-wrapper {
                padding: 0 14px 0 24px;
                .history-header {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-top: 11px;
                  .select-tab {
                    position: relative;
                    background: transparent;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    span {
                      font-weight: 700;
                      font-size: 28px;
                      line-height: 34px;
                      color: #ffffff;
                      &.btn-arrow {
                        width: 28px;
                        height: 28px;
                        background: #ffcf00;
                        border: 2px solid #000000;
                        box-shadow: 3px 3px 0px #000000;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 100px;
                        img {
                          width: 13px;
                        }
                      }
                    }
                    .tabs-bar {
                      display: none;
                      position: absolute;
                      top: 130%;
                      left: 0;
                      &.active {
                        display: block;
                      }
                      .nav-tabs {
                        border: 2px solid #000000;
                        border-radius: 24px;
                        padding: 14px;
                        background: #ffffff;
                        position: relative;
                        z-index: 100;
                        ul {
                          border: 0;
                          flex-direction: column;
                          gap: 14px;
                          height: unset;
                          li {
                            border-radius: 14px;
                            filter: unset;
                            border: 2px solid #000000;
                          }
                        }
                      }
                    }
                  }
                  .history-filter {
                    width: 28px;
                    height: 28px;
                    background: #ffcf00;
                    border: 2px solid #000000;
                    box-shadow: 3px 3px 0px #000000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100px;
                    position: relative;
                    > button {
                      border: 0;
                      background: transparent;
                    }
                    img {
                      width: 13px;
                    }
                    .toggle-button {
                      display: none;
                    }
                  }
                }
                .sort-filter-mobile {
                  display: none;
                }
                .history-content {
                  .tab-item {
                    .table-container {
                      position: relative;
                      &__header {
                        padding: 16px 20px;
                        padding-right: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &.date.asc {
                          .table-container__header--col-1 > span::after {
                            transform: rotate(180deg);
                          }
                        }
                        &.xp_from.asc {
                          .table-container__header--col-2 > span::after {
                            transform: rotate(180deg);
                          }
                        }
                        &.xp_gained.asc {
                          .table-container__header--col-3 > span::after {
                            transform: rotate(180deg);
                          }
                        }
                        &--col-1,
                        &--col-2,
                        &--col-3 {
                          flex: 1;
                          display: flex;
                          > span {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 20px;
                            color: #ffffff;
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            .moca-tooltip {
                              top: -3px;
                              &-label {
                                > svg {
                                  bottom: 0;
                                }
                              }
                            }
                            &::after {
                              background-image: url('../../images/arrow-down-white.svg');
                              background-position-y: -1px;
                              background-repeat: no-repeat;
                              background-size: 100%;
                              content: '';
                              display: block;
                              width: 11px;
                              height: 7px;
                            }
                          }
                        }
                        &--col-2 {
                          flex: 2;
                          justify-content: flex-start;
                        }
                        &--col-3 {
                          justify-content: flex-end;
                        }
                      }
                      &__item {
                        background: #f2f2f2;
                        border: 2px solid #000000;
                        box-shadow: 2px 2px 0px #000000;
                        border-radius: 12px;
                        &--header {
                          padding: 9px 20px;
                          border-radius: 12px;
                          background-color: #ffffff;
                          background-image: url(../../images/user-board/bg-color-history.svg);
                          background-repeat: no-repeat;
                          background-position: 100%;
                          max-height: 65px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          position: relative;
                          &-col-1,
                          &-col-2,
                          &-col-3 {
                            flex: 1;
                            display: flex;
                            align-items: center;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 24px;
                            color: #000000;
                          }
                          &-col-2 {
                            flex: 2;
                            justify-content: flex-start;
                            font-weight: 600;
                          }
                          &-col-3 {
                            justify-content: center;
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 32px;
                          }
                          .btn-showMore {
                            position: absolute;
                            border: 0;
                            right: 18px;
                            width: 28px;
                            height: 28px;
                            background: #ffcf00;
                            border: 2px solid #000000;
                            box-shadow: 3px 3px 0px #000000;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 100px;
                            > img {
                              width: 13px;
                            }
                            &.toggle {
                              img {
                                transform: rotate(180deg);
                              }
                            }
                          }
                        }
                        &--header--mobile {
                          display: none;
                        }
                        &--showMore {
                          background: #fff9be;
                          padding: 5px 20px;
                          display: none;
                          border-bottom-left-radius: 12px;
                          border-bottom-right-radius: 12px;
                          &.toggle {
                            display: block;
                          }
                          &-title {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 16px;
                            color: #948655;
                            margin-bottom: 9px;
                            padding-bottom: 7px;
                            border-bottom: 1px solid #e5d7a6;
                          }
                          &-list {
                            margin: 0;
                            display: flex;
                            flex-wrap: wrap;
                            &-item {
                              display: flex;
                              flex-direction: column;
                              gap: 3px;
                              margin-bottom: 11px;
                              &.bold {
                                width: 32%;
                                margin-left: 0 !important;
                                .title {
                                  font-weight: 700;
                                  font-size: 16px;
                                  line-height: 20px;
                                  color: #5d4b1e;
                                }
                                .value {
                                  font-weight: 600;
                                  font-size: 20px;
                                  line-height: 24px;
                                  color: #000000;
                                }
                              }
                              &:not(:first-child) {
                                margin-left: 23px;
                                @media only screen and (max-width: 992px) {
                                  margin-left: unset;
                                }
                              }
                              &:nth-child(5) {
                                margin-left: 0;

                                @media only screen and (max-width: 992px) {
                                  margin-left: unset;
                                }
                              }
                              .title {
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 20px;
                                color: #5d4b1e;
                                margin-bottom: 0;
                                &.contest {
                                  color: red;
                                }
                              }
                              .value {
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 24px;
                                color: #000000;
                                margin-bottom: 0;
                                &.contest {
                                  color: red;
                                }
                              }
                            }
                          }
                          &-description {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 15px;
                            color: #948655;
                            margin-bottom: 0;
                          }
                        }
                      }
                      .moca-history-list-wrapper {
                        height: 418px;
                        overflow-y: auto;
                        overflow-x: hidden;
                        padding-right: 10px;
                        &::-webkit-scrollbar {
                          background: none;
                          width: 6px;
                          height: 90px !important;
                        }
                        &::-webkit-scrollbar-corner {
                          background: none;
                        }
                        &::-webkit-scrollbar-thumb {
                          background: #ffcf00;
                          border-radius: 6px;
                        }
                        .moca-history-list {
                          display: flex;
                          flex-direction: column;
                          gap: 6px;
                        }
                      }
                      .table-container__pagination {
                        position: absolute;
                        // bottom: -14%;
                        width: 100%;
                      }
                    }
                  }
                }
                @media only screen and (max-width: 992px) {
                  padding: 0;
                  .history-header {
                    margin-top: 17px;
                    padding-left: 10px;
                    .select-tab {
                      gap: 7px;
                      padding: 0;
                      span {
                        font-weight: 700;
                        font-size: 28px;
                        line-height: 34px;
                        color: #ffffff;
                      }
                    }
                    .history-filter {
                      display: none;
                    }
                    .history-filter {
                      .mocana-filter-history {
                        position: fixed;
                        bottom: 0;
                        top: unset;
                        left: 0;
                        width: 100%;
                        max-height: 75%;
                        z-index: 100;
                        .filter-wrapper,
                        .sort-wrapper {
                          overflow: scroll;
                        }
                        h2 {
                          justify-content: flex-start;
                          .text-filter {
                            order: 2;
                            margin-left: 20%;
                          }
                          .text-reset {
                            order: 1;
                            padding-right: 6px;
                            padding-left: 0;
                          }
                        }
                        .toggle-button {
                          position: absolute;
                          top: -28px;
                          right: 21px;
                          width: 55px;
                          height: 55px;
                          border-radius: 50%;
                          border: 3px solid #000000;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          background-color: #f8d748;
                        }
                      }
                    }
                  }
                  .sort-filter-mobile {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 7px 0;
                    padding: 0 5px;
                    .moca-filter > button {
                      align-items: center;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 20px;
                      color: #ffffff;
                      display: flex;
                    }
                    .moca-filter,
                    .moca-sort {
                      width: unset;
                      height: unset;
                      box-shadow: none;
                      background: transparent;
                      border: 0;
                      display: flex;
                      gap: 4px;
                      align-items: center;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 20px;
                      color: #ffffff;
                    }
                    .mocana-list-filter,
                    .mocana-list-sort {
                      position: fixed;
                      bottom: 0;
                      top: unset;
                      left: 0;
                      width: 100%;
                      max-height: 75%;
                      z-index: 100;
                      border-color: #000000;
                      max-width: 100%;
                      border-bottom-left-radius: 0;
                      border-bottom-right-radius: 0;
                      .filter-wrapper,
                      .sort-wrapper {
                        overflow: scroll;
                      }
                      h2 {
                        justify-content: flex-start;
                        border-color: #000000;
                        .text-filter {
                          order: 2;
                          margin-left: 20%;
                        }
                        .text-reset {
                          order: 1;
                          padding-right: 6px;
                          padding-left: 0;
                        }
                      }
                      .toggle-button {
                        position: absolute;
                        top: -28px;
                        right: 21px;
                        width: 55px;
                        height: 55px;
                        border-radius: 50%;
                        border: 3px solid #000000;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #f8d748;
                      }
                      .group-btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 14px;
                        .btn-apply-filter {
                          margin: 6px 0 24px 0;
                        }
                        .btn-cancel {
                          background: #ffffff;
                          &:hover {
                            background: #ffffff;
                          }
                        }
                      }
                    }
                  }
                  .history-content {
                    .tab-item {
                      .table-container {
                        &__header {
                          padding: 7px 6px;
                          display: none;
                          &--col-1,
                          &--col-2,
                          &--col-3 {
                            > span {
                              font-size: 12px;
                              .moca-tooltip {
                                display: none;
                              }
                              &::after {
                                width: 15px;
                                background-size: contain;
                              }
                            }
                          }
                        }
                        .moca-history-list-wrapper {
                          padding-right: 0;
                          max-height: 260px;
                          .moca-history-list {
                            .table-container__item {
                              &--header {
                                display: none;
                              }
                              &--header--mobile {
                                padding: 9px;
                                background-image: url('../../images/user-board/bg-color-history-mobile.svg');
                                background-repeat: no-repeat;
                                background-position: 100%;
                                display: flex;
                                align-items: center;
                                height: 52px;
                                border-radius: 12px;
                                position: relative;
                                &-col-1 {
                                  flex: 2;
                                  display: flex;
                                  justify-content: flex-start;
                                  flex-direction: column;
                                  align-items: flex-start;
                                  gap: 4px;
                                  .status {
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 14px;
                                    color: #000000;
                                  }
                                  .date {
                                    font-weight: 500;
                                    font-size: 10px;
                                    line-height: 10px;
                                    color: #333333;
                                    opacity: 0.8;
                                  }
                                }
                                &-col-3 {
                                  flex: 1;
                                  display: flex;
                                  justify-content: center;
                                  font-weight: 700;
                                  font-size: 16px;
                                  line-height: 20px;
                                  color: #000000;
                                }
                                .btn-showMore {
                                  position: absolute;
                                  border: 0;
                                  right: 18px;
                                  width: 21px;
                                  height: 21px;
                                  background: #ffcf00;
                                  border: 2px solid #000000;
                                  box-shadow: 3px 3px 0px #000000;
                                  display: flex;
                                  align-items: center;
                                  justify-content: center;
                                  border-radius: 100px;
                                  > img {
                                    width: 13px;
                                  }
                                  &.toggle {
                                    img {
                                      transform: rotate(180deg);
                                    }
                                  }
                                }
                              }
                              &--showMore {
                                padding: 8px 13px;
                                &-title {
                                  font-style: normal;
                                  font-weight: 600;
                                  font-size: 12px;
                                  line-height: 16px;
                                  color: #948655;
                                }
                                &-list {
                                  flex-wrap: wrap;
                                  &-item {
                                    width: 50%;
                                    .title {
                                      font-weight: 700;
                                      font-size: 12px;
                                      line-height: 16px;
                                      color: #5d4b1e;
                                    }
                                    .value {
                                      font-weight: 700;
                                      font-size: 14px;
                                      line-height: 16px;
                                      color: #000000;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        .table-container__pagination {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
              .progress-wrapper {
                padding-top: 100px;
                position: relative;
                display: flex;
                align-items: center;
                gap: 5px;
                .bar {
                  background: #fff;
                  height: 30px;
                  border: 2px solid #000000;
                  border-radius: 21px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  width: 100%;
                  .progress {
                    width: 75%;
                    height: 21px;
                    border-radius: 21px;
                    background: #d9d9d9;
                    border: 2px solid #000000;
                    margin-left: 4px;
                    position: relative;
                    > img {
                      width: 52px;
                      height: 52px;
                      position: absolute;
                      right: -16px;
                      top: -16px;
                    }
                    &::after,
                    &::before {
                      content: '';
                      position: absolute;
                      display: block;
                      width: 0px;
                      bottom: 56px;
                      transform: translate(-50%, calc(100% + 5px));
                    }
                    &:after {
                      border: 7px solid transparent;
                      border-bottom: 0;
                      border-top: 9px solid #ffffff;
                      right: -7px;
                      z-index: 10;
                    }
                    &:before {
                      border: 10px solid transparent;
                      border-bottom: 0;
                      border-top: 12px solid #000000;
                      right: -13px;
                    }
                  }
                }
                .image-hundred {
                  max-width: 39px;
                  max-height: 36px;
                }
                .moca-xp {
                  position: absolute;
                  top: 16px;
                  left: 0;
                  width: 100%;
                  padding: 6px 18px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border: 2px solid #000000;
                  background-color: #ffffff;
                  border-radius: 20px;
                  .title {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    color: #575757;
                  }
                  .xp {
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 48px;
                    color: #000000;
                  }
                }
              }
              .top-xp {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 9px;
                margin-top: 16px;
                span.xp-title {
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 24px;
                  color: #575757;
                }
                span.xp-quantity {
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 24px;
                  color: #000000;
                }
              }
              @media only screen and (max-width: 992px) {
                padding: 0 16px;
                .nav-tabs {
                  padding: 0 4px;
                  border-bottom: 0;
                  ul {
                    width: 100%;
                    height: 36px;
                    li {
                      min-width: unset;
                      width: 50%;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 20px;
                      color: #333333;
                      &.active {
                        font-weight: 700;
                      }
                    }
                  }
                }
              }
            }
          }
          .sub-bg-modal {
            position: absolute;
            bottom: 0px;
            right: 0px;
            @media only screen and (max-width: 992px) {
              bottom: 0px;
              right: 4px;
            }
          }
        }
        .table-container__pagination {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 1.5rem auto;
          gap: 16px;
          &--prev,
          &--next {
            img {
              transform: rotate(90deg);
              width: 22px;
              margin-top: 3px;
            }
          }
          &--next img {
            transform: rotate(-90deg) !important;
          }
          select {
            font-family: inherit;
            font-size: 1.4rem;
            font-weight: 800;
            padding: 8px 28px;
            width: 100px;
            border-radius: 30px;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-image: url(../../images/arrow-down.svg);

            background-repeat: no-repeat;
            background-position: right 0.8em top 50%;
            background-size: 1em auto;
            box-shadow: 4px 4px 0px #000000;
            background-color: #f8d748;
          }
          &--select {
            font-family: inherit;
            font-size: 1.4rem;
            font-weight: 800;
            padding: 10px 28px;
            width: 110px;
            border-radius: 30px;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-image: url(../../images/arrow-down.svg);
            background-repeat: no-repeat;
            background-position: right 0.8em top 50%;
            background-size: 1em auto;
            box-shadow: 4px 4px 0px #000000;
            background-color: #f8d748;
            position: relative;
            &--option {
              background: #f8d748;
              border: 1px solid #000000;
              box-shadow: 4px 4px 0px #000000;
              border-radius: 8px;
              position: absolute;
              width: 100%;
              bottom: 110%;
              left: 0;
              ul {
                margin: 0;
                padding: 0;
                max-height: 246px;
                overflow: scroll;
                &::-webkit-scrollbar {
                  background: none;
                  height: 6px;
                  width: 7px;
                }
                &::-webkit-scrollbar-corner {
                  background: none;
                }
                &::-webkit-scrollbar-thumb {
                  background: rgba(136, 111, 85, 0.5);
                  border-radius: 100px;
                }
                li {
                  padding: 6px 16px;
                  &:hover {
                    background: #ffa800;
                  }
                }
              }
              .last-item {
                border-top: 1px solid rgba(0, 0, 0, 0.5);
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                padding: 6px 16px;
                &:hover {
                  background: #ffa800;
                }
              }
            }
          }

          &--label {
            color: #000;
            font-weight: 600;
            font-size: 20px;
          }
        }
      }
    }
  }
  .mocana-list-filter,
  .mocana-list-sort,
  .mocana-filter-history {
    position: absolute;
    top: 130%;
    right: 0;
    background: #0080ce;
    border: 1px solid;
    border-radius: 14px;
    z-index: 1;
    display: none;
    max-width: 225px;
    &.active {
      display: block;
    }
    .filter-wrapper,
    .sort-wrapper {
      overflow: hidden;
      border-radius: 14px;
    }
    .btn-apply-filter {
      text-align: center;
      margin: 6px 17px 24px;
      font-weight: 700;
      font-size: 1rem;
      /* line-height: 32px; */
      color: #000000;
      background: #f8d748;
      border: 2px solid #000000;
      box-shadow: 3px 5px 0px #000000;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 40px;
    }
    .group-btn {
      display: flex;
      justify-content: center;

      .btn-cancel {
        display: none;
        background-color: #fff;
        @media only screen and (max-width: 767px) {
          display: inline-block;
        }
      }
    }
    .sub-filter-section {
      padding: 19px 17px;
      h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        opacity: 0.8;
        text-align: left;
      }
      ul {
        margin: 10px 10px 10px 0;
        min-width: 220px;
      }
      li {
        margin: 12px 0;
        display: flex;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        img {
          width: 20px;
          margin-top: -3px;
        }
        .custom-input {
          display: flex;
          position: relative;
          padding-left: 27px;
          margin-bottom: 0;
          cursor: pointer;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          &:hover input ~ .checkmark {
            background-color: #ccc;
          }
          &:after {
            content: '';
            position: absolute;
            display: none;
          }
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 100%;
            width: 100%;
            left: 0;
            right: 0;
            z-index: 100;
            &:checked ~ .checkmark {
              background-color: #000000;
            }
            &:checked ~ .checkmark.radio {
              background-color: #ffffff;
            }
            &:checked ~ .checkmark:after {
              display: block;
            }
          }
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #fff;
            border: 2px solid #000000;
            border-radius: 4px;
            &:after {
              content: '';
              display: none;
              position: absolute;
              left: 4px;
              top: 0px;
              width: 5px;
              height: 10px;
              border: solid white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
            &.radio {
              border-radius: 100px;
              &:after {
                content: '';
                display: none;
                position: absolute;
                left: calc((100% - 10px) / 2);
                top: calc((100% - 10px) / 2);
                width: 10px;
                height: 10px;
                background: #000000;
                border: 0;
                border-radius: 100px;
              }
            }
          }
        }
      }
    }
    h2 {
      background-color: #004975;
      border-bottom: 1px solid;
      padding: 9px 17px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text-filter {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.8);
      }
      .text-reset {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-decoration-line: underline;
        color: #ffffff;
        padding-right: 0;
      }
      button {
        border: 0;
        background: transparent;
        font-weight: bold;
        font-size: 0.9rem;
        text-decoration: underline;
      }
    }
    .filter {
      text-align: right;
      select {
        border: transparent;
        font-weight: 700;
        background: #f7aa17;
        padding: 10px 20px;
        &:focus {
          outline: none;
        }
      }
    }
    .toggle-button {
      display: none;
    }
  }
  .mocana-list-sort {
    display: none;
  }
  @media only screen and (max-width: 767px) {
    .moca-search-filter {
      .mocana-filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
        .mocana-button-filter {
          img {
            opacity: 0.5;
            max-width: 16px;
          }
          button {
            padding: 0;
            box-shadow: none;
            border: 0;
            flex-direction: row-reverse;
            background: transparent;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
            > img {
              margin-left: 6px;
              filter: brightness(0) invert(1);
              opacity: 1;
            }
          }
        }
        .mocana-button-sort {
          display: block;
          button {
            border: 0;
            background: transparent;
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
            > img {
              margin-left: 6px;
              filter: brightness(0) invert(1);
            }
          }
        }
      }
      .mocana-list-filter,
      .mocana-list-sort {
        position: fixed;
        bottom: 0;
        top: unset;
        left: 0;
        width: 100%;
        max-height: 75%;
        z-index: 100;
        max-width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        .filter-wrapper,
        .sort-wrapper {
          overflow: scroll;
        }
        h2 {
          justify-content: flex-start;
          .text-filter {
            order: 2;
            margin-left: 20%;
          }
          .text-reset {
            order: 1;
            padding-right: 6px;
            padding-left: 0;
          }
        }
        .toggle-button {
          position: absolute;
          top: -28px;
          right: 21px;
          width: 55px;
          height: 55px;
          border-radius: 50%;
          border: 3px solid #000000;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f8d748;
        }
      }
      .mocana-list-sort {
        // display: block;
      }
    }
  }
}
