.modal-wappar {
  .outer {
    width: 343px;
    height: auto;
    .content {
      padding-top: 0;
      padding-bottom: 10px;
      .modal-content {
        justify-content: flex-start;
        height: auto;
      }
    }
    @media only screen and (max-width: 374px) {
      width: 280px;
      height: auto;
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
    margin: 15px;
  }

  h3 {
    color: #6d6d6d;
    margin: 15px;
  }

  #metamask,
  #coinbase,
  #walletconnect {
    width: 289px;
    height: 64px;
    display: block;
    cursor: url(../../images/cursor.svg), auto;
    border: 0;
    outline: 0;
    background: transparent;
    margin-bottom: 5px;
    padding: 0;

    &:hover {
      transform: scale(1.05);
    }

    @media only screen and (max-width: 374px) {
      width: 80%;
      height: auto;

      img {
        width: 100%;
      }
    }
  }
}
