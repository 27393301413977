.dustland-page {
  .mocana-wrapper {
    background: url(../../images/mocana/banner_dustland.svg) 50% 0 no-repeat;
    position: relative;
    @media only screen and (min-width: 1441px) {
      background-size: 100%;
    }
    .back-to-home {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .mocana-main {
    .event-connect-wallet:not(.event-closed) {
      padding: 20px 12px 16px;
      .hot-wallet-info {
        margin-top: 20px;
        width: 88%;
      }
    }
    .event-sb-mobile {
      left: 12px;
    }
    .event-title-wrapper {
      margin: 0 0 36px;
    }
    .event-main {
      .table-container {
        width: 100%;
        &.realm-dustland {
          .table-container__header {
            &--col-1 {
              min-width: 150px;
            }
            &--col-2 {
              min-width: 185px;
            }
            &--col-3 {
              min-width: 128px;
            }
            &--col-4 {
              min-width: 220px;
            }
            &--col-5 {
              min-width: 130px;
            }
            &--col-6 {
              min-width: 142px;
              justify-content: end;
              left: 0;
            }
            &--col-5::after {
              display: none;
            }
            &--col-3::before,
            &--col-6::before {
              content: '';
              display: block;
              width: 20px;
              height: 20px;
              background-image: url(../../images/info.svg);
              display: none;
            }
          }

          .table-container__item {
            &--col-1 {
              min-width: 145px;
            }
            &--col-2 {
              min-width: 210px;
              @media only screen and (max-width: 520px) {
                min-width: 175px;
              }
            }
            &-col-3 {
              min-width: 128px;
            }
            &--col-4 {
              min-width: 112px;
            }
            &--col-5 {
              min-width: 180px;
              @media only screen and (max-width: 520px) {
                min-width: 135px;
              }
              a {
                color: #0080ce;
              }
            }
            &--col-6 {
              min-width: 142px;
              flex-grow: 1;
              text-align: right;
              &.right {
                text-align: right;
              }
            }
            &--col-7 {
              min-width: 220px;
            }
          }
        }
        &__header {
          display: flex;
          align-items: center;
          margin: 20px 0 8px 0;
          padding: 0;
          justify-content: flex-start;
          gap: 25px;
          @media only screen and (max-width: 991px) {
            display: none;
          }
          &.active-max_streaks {
            .table-container__header--col-3::after {
              transform: rotate(180deg);
            }
          }
          span {
            color: #fff;
            font-weight: 700;
            font-size: 14px;
          }
          &--col-1 {
            min-width: 78px;
          }
          &--col-2 {
            min-width: 150px;
          }
          &--col-3 {
            min-width: 128px;
          }
          &--col-4 {
            min-width: 130px;
          }
          &--col-5 {
            min-width: 103px;
          }
          &--col-6 {
            min-width: 142px;
            justify-content: end;
            left: 0;
            flex-grow: 1;
          }
          &--col-5::after {
            display: none;
          }
          &--col-3::before,
          &--col-6::before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            background-image: url(../../images/info.svg);
            display: none;
          }
        }

        &__item {
          background-image: url(../../images/item_table_dustland.svg);
          padding: 8px 30px;
          justify-content: flex-start;
          @media only screen and (max-width: 991px) {
            background-image: unset;
          }
          &--col-1 {
            min-width: 73px;
          }
          &--col-2 {
            min-width: 175px;
            @media only screen and (max-width: 520px) {
              min-width: 110px;
            }
          }
          &-col-3 {
            min-width: 128px;
          }
          &--col-4 {
            min-width: 152px;
          }
          &--col-5 {
            min-width: 132px;
          }
          &--col-6 {
            min-width: 142px;
            flex-grow: 1;
            // padding-right: 25px;
          }
          &--col-7 {
            min-width: 95px;
            font-weight: 800;
            text-align: center;
            font-size: 20px;
          }
        }
      }
    }
    @media only screen and (min-width: 992px) {
      .event-main {
        min-width: 810px;
        .tribe-rank-list .tribe-ranks .tribe-rank {
          max-width: 48.2%;
          width: 48.2%;
        }
      }
    }
  }
  .list-tabs ul {
    @media only screen and (max-width: 991px) {
      gap: 8px;
    }
    li button {
      padding: 14px 24px;
      bottom: -6px;
      &.active {
        padding: 16px 24px;
        bottom: -2px;
      }
      @media only screen and (max-width: 991px) {
        padding: 8px 9px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        bottom: -2px;
        &.active {
          bottom: -2px;
          padding: 8px 9px;
        }
      }
    }
  }
  .event-section {
    margin: 0 0 20px;
    .event-board-header .event-board-title {
      @media only screen and (max-width: 520px) {
        width: 104%;
        text-align: center;
      }
    }
    &.event-requirement {
      margin: 0 0 40px;
      .event-section-content.dustland-section {
        p,
        li {
          font-size: 16px;
          color: #fff;
        }
        @media only screen and (max-width: 768px) {
          li {
            font-size: 14px;
          }
        }
      }
    }
    &.event-ht-register {
      p.guide-step-1 a {
        text-decoration: underline;
      }
      .event-section-content {
        .note {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
  .tab-content .moca-table .sort-filter {
    display: block;
    .moca-search-filter {
      margin-top: 24px;
    }
  }
}
.registed-wallet-info {
  padding: 12px 0;
  border-top: 1px solid #ededed;
  margin-top: 12px;
  width: 100%;
  .wallet-container {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    span {
      font-size: 14px;
      font-weight: 600;
      &.bold {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
}
.hot-wallet-info {
  text-align: center;
  background: rgba(191, 255, 127, 0.3);
  border-radius: 10px;
  padding: 5px;
  margin-top: 10px;
  &.registering {
    background: #bfff7f;
  }
  .wallet-container {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    span.bold {
      font-weight: 600;
    }
  }
  p {
    white-space: nowrap;
    font-size: 14px;
    margin: 4px 0;
    font-weight: 600;

    a {
      color: #0080ce;
    }
  }
  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
}
