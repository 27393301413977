.mocana-wrapper:not(.event-wrapper) {
  .tab-content {
    @media only screen and (max-width: 991px) {
      padding: 0 12.5px;
    }
  }
  .moca-table:not(.moca-table-realm) {
    .table-container {
      &__header.active--rank {
        .table-container__header--col-1::after {
          transform: rotate(180deg);
        }
      }
      &__header.active-rank {
        .table-container__header--col-1::after {
          transform: none;
        }
      }
      &__item {
        &--col-5 {
          min-width: 250px;
          .link-opensea {
            left: 135px;
          }
        }
        &.mobile {
          overflow: hidden;
          .table-container__item-top {
            background-color: #fdef72;
          }
        }
      }
      &__header--col-4 {
        min-width: 135px;
      }
      &__header--col-5 {
        min-width: 216px;
      }
      &__header--col-5,
      &__header--col-6 {
        &:after {
          opacity: 0;
        }
      }
      &__item--col-5 > a:first-child {
        color: #0080ce;
        font-size: 16px;
      }
      &__item--col-2 span,
      &__item--col-4 {
        font-size: 16px;
      }
      &__item--col-4 {
        color: #333333;
      }
      &__item--col-6 {
        font-size: 28px;
      }
      &__item--col-2 > span {
        @media only screen and (max-width: 991px) {
          font-size: 24px;
          top: 8px;
        }
      }
    }
  }
  .realm-header {
    .content-header-left-title {
      @media only screen and (max-width: 991px) {
        gap: 4px;
        span {
          width: 285px;
          min-width: 285px;
        }
      }
    }
  }
  .moca-table.moca-table-realm {
    .moca-search input {
      padding: 15px 0 14px 8px;
      @media only screen and (max-width: 991px) {
        padding: 0 0 0 6px;
      }
    }
    .table-container {
      &__header.active--wallet_address {
        .table-container__header--col-2::after {
          transform: rotate(180deg);
        }
      }
      &__header.active--rank {
        .table-container__header--col-1::after {
          transform: rotate(180deg);
        }
      }
      &__header.active-rank {
        .table-container__header--col-1::after {
          transform: none;
        }
      }
      &__item {
        .moca-tooltip-content {
          transform: translateX(86%);
          max-width: 340px;
        }
      }
      &__item.mobile {
        .table-container__item-top {
          background-color: #fdef72;
          padding-left: 23.5px;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }
      }
      &__item--col-2 {
        min-width: 250px;
        @media only screen and (max-width: 768px) {
          min-width: unset;
        }
        a {
          color: #0080ce;
        }
      }
      &__header--col-2 {
        // left: 54px;
      }
      &__header--col-6 {
        &::after {
          display: none;
        }
      }
    }
  }
}
