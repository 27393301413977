.mocana-header-wrapper {
  position: relative;
  background-image: url(../../images/mocana-header/background-header.svg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0 26px;
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom,
      rgb(255 255 255 / 0%),
      rgb(15 34 63 / 92%) 90%
    );
    width: 100%;
    height: 2.5em;
  }
  a.back-to-home {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1110;
    img {
      max-width: 80px;
    }
  }
  .mocana-tag {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mocana-sub-title {
    font-weight: 900;
    font-size: 24px;
    line-height: 48px;
    text-align: center;
    color: #ffcf00;
    margin-bottom: 0;
  }
  .mocana-title {
    max-width: 400px;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: #ffcf00;
    margin: 0 auto;
  }
  .mocana-time {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 30px;
  }
  .mocana-step {
    max-width: 680px;
    margin: 0 auto 24px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      position: relative;
      &-number {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        font-size: 29.4px;
        line-height: 120%;
        text-align: center;
        color: #000000;
        border-radius: 50%;
        border: 4px solid #fff;
        width: 48px;
        height: 48px;
        background: #ffffff;
        margin-bottom: 8px;
      }
      &-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 2px;
      }
      &-description {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 0px;
      }
      .arrow-step {
        position: absolute;
        right: -5%;
        top: 10%;
      }
    }
  }
  .mocana-button-check {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 13px;
    .button-check-ranking,
    .button-learn-more {
      font-weight: 700;
      font-size: 18px;
      line-height: 32px;
      color: #000000;
      text-decoration: none;
      background: #ffcf00;
      border: 2px solid #000000;
      box-shadow: 7px 9px 0px #000000;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 24px;
      &:hover {
        background-color: #ffb800;
        box-shadow: none;
        position: relative;
        bottom: -7px;
      }
    }
    .button-learn-more {
      margin-left: 18px;
    }
  }
  .mocana-description {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 0;
    .text-white {
      font-weight: 700;
      color: rgba(255, 255, 255, 1);
    }
  }
  .list-social-mobile {
    display: none;
  }
  .blur-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
@media only screen and (max-width: 1200px) {
  .mocana-header-wrapper {
    .mocana-step {
      max-width: 680px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .mocana-header-wrapper {
    background-image: url(../../images/mocana-header/background-header-mobile.svg);
    background-size: contain;
    padding: 72px 0 26px;
    margin-top: 64px;
    &:after {
      display: none;
    }
    a.back-to-home {
      img {
        max-width: 53px;
      }
    }
    .mocana-tag {
      margin: 25% 0 0px;
      > img {
        max-width: 182px;
      }
    }
    .mocana-sub-title {
      font-weight: 900;
      font-size: 15px;
      line-height: 31px;
    }
    .mocana-title {
      font-weight: 900;
      font-size: 39px;
      line-height: 39px;
      margin-bottom: 4px;
    }
    .mocana-time {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 40px;
    }
    .mocana-step {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 32px;
      &-item {
        max-width: 300px;
        &:not(:last-child) {
          margin-bottom: 32px;
        }
        &-number {
          background: transparent;
          color: #fff;
          margin-bottom: 9px;
        }
        &-title {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
        }
        &-description {
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          .big-text {
            font-weight: 700;
            color: rgba(255, 255, 255, 1);
          }
        }
      }
      .arrow-step {
        display: none;
      }
    }
    .mocana-button-check {
      .button-learn-more {
        display: none;
      }
    }
    .mocana-description {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 32px;
      max-width: 280px;
      margin: 8px auto 0;
    }
    .list-social-mobile {
      display: block;
      position: relative;
      z-index: 10;
      max-width: 227px;
      margin: 24px auto 0;
      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .mocana-header-wrapper {
    .mocana-tag {
      margin: 15% 0 0px;
    }
  }
}
