.moca-tooltip {
  display: inline-flex;
  position: relative;

  &-label {
    display: inline-flex;
    svg {
      position: relative;
      bottom: -4px;
    }
  }

  &:hover {
    .moca-tooltip-content {
      opacity: 1;
      visibility: visible;
      display: block;
    }
  }

  &-content {
    background: #ffffff;
    border: 2px solid #000000;
    border-radius: 16px;
    bottom: 60px;
    left: 41%;
    max-width: 488px;
    opacity: 0;
    padding: 20px;
    position: absolute;
    transform: translateX(-41%);
    visibility: hidden;
    width: max-content;
    z-index: 10;
    box-shadow: 5px 7px 0 #000;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    display: none;

    @media only screen and (max-width: 992px) {
      max-width: 320px;
      left: auto;
      right: 0;
      transform: translateX(25%);
      z-index: 8999;
    }

    @media only screen and (max-width: 374px) {
      max-width: 300px;
      transform: translateX(20%);
    }

    &:after {
      border-color: #000 transparent transparent;
      border-style: solid;
      border-width: 36px 20px 36px 20px;
      content: '';
      left: 37%;
      margin-left: 1px;
      position: absolute;
      top: 100%;
      z-index: 1;

      @media only screen and (max-width: 992px) {
        left: auto;
        right: 22%;
      }

      @media only screen and (max-width: 374px) {
        right: 26%;
      }
    }

    &::before {
      border-color: #fff transparent transparent;
      border-style: solid;
      border-width: 25px 15px;
      content: '';
      left: 37%;
      margin-left: 3px;
      position: absolute;
      top: 100%;
      z-index: 10;

      @media only screen and (max-width: 992px) {
        left: auto;
        right: 25%;
        margin-left: 0;
        margin-right: -3px;
      }

      @media only screen and (max-width: 374px) {
        right: 19%;
        margin-right: -2px;
      }
    }

    h2 {
      font-weight: 800;
      font-size: 24px;
      line-height: 29px;
      margin: 0 0 10px;

      @media only screen and (max-width: 992px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 18px;

      @media only screen and (max-width: 992px) {
        font-size: 14px;
      }
    }

    ul {
      margin: 0;
      list-style-type: disc;
      padding: 0 0 0 20px;
      @media only screen and (max-width: 992px) {
        font-size: 14px;
      }
      li {
      }
    }
    .moca-XP-tooltip {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 8px;
        li {
          p {
            margin-bottom: 0;
            &.title {
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              color: #000000;
              > span {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #737373;
              }
            }
            &.des {
              padding: 5px 15px;
              background: #ededed;
              border-radius: 8px;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              > span {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #000000;
                &.des-title {
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 16px;
                  color: #000000;
                  padding-bottom: 3px;
                  border-bottom: 1px solid #c2c2c2;
                  width: 100%;
                  margin-bottom: 7px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.moca-event-tooltip {
  @media only screen and (max-width: 374px) {
    display: block;
  }

  .moca-tooltip-content {
    bottom: 52px;
    right: -14%;
    transform: translateX(14%);
    left: auto;

    @media only screen and (max-width: 992px) {
      right: -9%;
      transform: translateX(9%);
    }

    @media only screen and (max-width: 374px) {
      right: 0;
      transform: translateX(9%);
    }

    &:after {
      margin-left: auto;
      left: auto;
      right: 13%;
      margin-right: -4px;

      @media only screen and (max-width: 992px) {
        right: 7%;
        margin-right: -2px;
      }

      @media only screen and (max-width: 374px) {
        right: 50%;
        transform: translateX(-50%);
        margin-right: -45px;
      }
    }
    &:before {
      margin-left: auto;
      left: auto;
      right: 14%;
      margin-right: -2px;

      @media only screen and (max-width: 992px) {
        right: 9%;
        margin-right: -1px;
      }

      @media only screen and (max-width: 374px) {
        right: 50%;
        transform: translateX(-50%);
        margin-right: -33px;
      }
    }
  }
  &.bottom-tooltip {
    .moca-tooltip-content {
      top: calc(100% + 37px);
      height: max-content;
      width: 477px;
      transform: translateX(14%);
      &::before {
        transform: rotate(180deg);
        bottom: 100%;
        top: unset;
      }
      &::after {
        transform: rotate(180deg);
        bottom: 100%;
        top: unset;
      }
      .moca-SoftStake-tooltip {
        .SoftStake-tooltip-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 14px;
          @media only screen and (max-width: 992px) {
            align-items: flex-start;
          }
          .header-name {
            font-weight: 800;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            gap: 5px;
          }
          .header-value {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 12px;
            line-height: 16px;
            color: #646464;
            h2 {
              font-weight: 800;
              font-size: 24px;
              line-height: 29px;
              color: #000000;
              margin-bottom: 0;
            }
            img {
              width: 20px;
            }
            @media only screen and (max-width: 992px) {
              display: flex;
              flex-direction: column;
              gap: 0;
              align-items: flex-end;
              span:last-child {
                display: flex;
                align-items: center;
                gap: 1.8px;
              }
            }
          }
        }
        .tribe-content {
          .tribe-title {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #000000;
          }
          .tribe-list {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
            gap: 17px;
            &-item {
              width: 30%;
              display: flex;
              flex-direction: column;
              gap: 4px;
              > img {
                max-width: 52px;
              }
              > span {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #333333;
              }
            }
          }
        }
        .description {
          padding: 12px 13px;
          background: #ededed;
          border-radius: 8px;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #000000;
          margin-bottom: 0;
          a {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            text-decoration-line: underline;
            color: #0080ce;
          }
        }
        .sub-description {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          margin-bottom: 6px;
          margin-top: 6px;
        }
        .whale-boost-list {
          .list {
            margin: 0;
            padding: 0;
            list-style: none;
            &-item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 19px 0;
              border-bottom: 2px solid #000000;
              &:last-child {
                border-bottom: 0;
              }
              &__moca-quantity {
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                img {
                  &:not(:first-child) {
                    margin-left: -15px;
                  }
                }
                span {
                  margin-left: 6px;
                }
              }
              &__moca-info {
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #646464;
                .benefit {
                  padding: 6px;
                  background: #fad722;
                  border-radius: 5px;
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 22px;
                  color: #1b3871;
                  min-width: 84px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                img {
                  width: 20px;
                }
              }
            }
          }
        }
        .message {
          display: flex;
          align-items: center;
          padding: 16px 8px;
          background-color: #fffae7;
          border-radius: 8px;
          gap: 8px;
          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
          }
        }
      }
      @media only screen and (max-width: 992px) {
        max-width: 306px;
        .moca-SoftStake-tooltip {
          .SoftStake-tooltip-header {
            .header-name {
              font-size: 18px;
              line-height: 22px;
              img {
                width: 28px;
              }
            }
            .header-value {
              h2 {
                font-size: 18px;
                line-height: 22px;
              }
            }
          }
          .description {
            font-size: 16px;
            line-height: 20px;
          }
          .sub-description {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
          }
          img.list-icon {
            max-width: 137px;
          }
          .whale-boost-list {
            .list {
              &-item {
                &__moca-quantity {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 20px;
                  color: #000000;
                  img {
                    width: 23px;
                    &:not(:first-child) {
                      margin-left: -10px;
                    }
                  }
                }
                &__moca-info {
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 16px;
                  gap: 5px;
                  .benefit {
                    min-width: 64px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    color: #1b3871;
                  }
                  img {
                    width: 14px;
                  }
                }
              }
            }
          }
          .message {
            span {
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
            }
          }
        }
      }
    }
    &.soft-tooltip {
      .moca-tooltip-content {
        right: unset;
        transform: translateX(-4%);
        &::after {
          right: 80%;
        }
        &::before {
          right: 81%;
        }
        @media only screen and (max-width: 992px) {
          right: 0;
          transform: translateX(90%);
          padding: 7px 10px;
          &::after {
            right: 80%;
          }
          &::before {
            right: 81%;
          }
        }
      }
    }
    &.boost-tooltip {
      .moca-tooltip-content {
        right: unset;
        transform: translateX(-17%);
        &::after {
          right: unset;
          left: 14%;
        }
        &::before {
          right: unset;
          left: 14%;
        }
        @media only screen and (max-width: 992px) {
          right: 0;
          transform: translateX(43%);
          padding: 7px 10px;
          &::after {
            right: unset;
            left: 49%;
          }
          &::before {
            right: unset;
            left: 49%;
          }
        }
      }
    }
    &.tribe-tooltip {
      .moca-tooltip-content {
        padding: 10px;
        right: unset;
        transform: translateX(-50%);
        &::after {
          right: 45%;
        }
        &::before {
          right: 45%;
        }
        .moca-SoftStake-tooltip {
          .SoftStake-tooltip-header {
            padding: 10px;
            padding-bottom: 0;
          }
          .tribe-content {
            padding: 8px 10px;
            background: #ededed;
            border-radius: 6.4px;
          }
        }
        @media only screen and (max-width: 992px) {
          right: 0;
          transform: translateX(90%);
          padding: 7px 10px;

          &::after {
            right: 80%;
          }
          &::before {
            right: 81%;
          }
          .moca-SoftStake-tooltip {
            .SoftStake-tooltip-header {
              padding: 5px;
              padding-bottom: 0;
            }
            .tribe-content {
              .tribe-title {
                font-size: 16px;
                line-height: 20px;
              }
              .tribe-list {
                justify-content: space-between;
                &-item {
                  width: max-content;
                  img {
                    width: 42px;
                  }
                  > span {
                    font-size: 14px;
                    line-height: 16px;
                  }
                  &:last-child {
                    width: 63%;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.whale-tooltip {
      .moca-tooltip-content {
        right: unset;
        transform: translateX(-70%);
        &::after {
          right: 24%;
        }
        &::before {
          right: 24%;
        }
        @media only screen and (max-width: 992px) {
          right: 0;
          transform: translateX(43%);
          padding: 7px 10px;
          &::after {
            right: unset;
            left: 49%;
          }
          &::before {
            right: unset;
            left: 49%;
          }
        }
        .moca-SoftStake-tooltip {
          .description {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            padding-bottom: 0;
            @media only screen and (max-width: 992px) {
              font-size: 14px;
              line-height: 16px;
            }
          }
          .whale-boost-list {
            background: #ededed;
            padding: 12px 13px;
            padding-top: 0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            margin-bottom: 20px;
            .list {
              .list-item {
                &:first-child {
                  .list-item__moca-quantity {
                    img {
                      &:not(:first-child) {
                        margin-left: -8px;
                      }
                    }
                  }
                }
                &:not(:last-child) {
                  border-bottom: 0.8px solid #c2c2c2;
                }
                &:last-child {
                  padding-bottom: 0;
                }
                .list-item__moca-info {
                  .benefit {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                    color: #000000;
                    padding: 0;
                    background: transparent;
                    border-radius: 0;
                  }
                  span {
                    display: flex;
                    align-items: flex-end;
                    gap: 2px;
                  }
                }
              }
            }
            @media only screen and (max-width: 992px) {
              margin-bottom: 9px;
              .list {
                &-item {
                  padding: 6px 0;
                  &:not(:last-child) {
                    border-bottom: 0.8px solid #c2c2c2;
                  }
                  &__moca-info {
                    flex-direction: column;
                    .benefit {
                      font-size: 16px;
                      line-height: 19px;
                    }
                  }
                }
              }
            }
          }
          .message {
            padding: 0 8px;
            background: transparent;
          }
        }
      }
    }
    &.staking-tooltip {
      .moca-tooltip-content {
        right: unset;
        transform: translateX(-70%);
        &::after {
          right: 24%;
        }
        &::before {
          right: 24%;
        }
        @media only screen and (max-width: 992px) {
          right: 0;
          transform: translateX(3%);
          padding: 7px 10px;
          &::after {
            right: unset;
            left: 80%;
          }
          &::before {
            right: unset;
            left: 80%;
          }
        }
      }
    }
    &.xp-tooltip {
      .moca-tooltip-content {
        right: unset;
        transform: translateX(-70%);
        &::after {
          right: 24%;
        }
        &::before {
          right: 24%;
        }
        // @media only screen and (max-width:992px) {
        //   transform: translateX(3%);
        //   padding: 7px 10px;
        //   &::after {
        //     right: unset;
        //     left: 80%;
        //   }
        //   &::before {
        //     right: unset;
        //     left: 80%;
        //   }
        // }
      }
    }
  }
  &.realm-tooltip {
    .moca-tooltip-label svg {
      @media only screen and (min-width: 992px) {
        bottom: 0;
      }
    }
    .moca-tooltip-content {
      p {
        white-space: break-spaces;
      }
      @media only screen and (max-width: 992px) {
        right: -9%;
        transform: translateX(88%);
        &:before {
          right: 82%;
        }
        &:after {
          right: 82%;
        }
      }
    }
  }
}

.moca-what-tribe-tooltip {
  .moca-tooltip-content {
    padding: 23px 20px 5px;
  }
}
