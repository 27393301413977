.timer {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.intro {
  scroll-snap-align: start;
  width: 100vw;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  box-sizing: border-box;

  @media only screen and (max-width: 900px) {
    padding: 0;
    box-sizing: border-box;
    min-height: 0px;
    height: auto;
    justify-content: flex-start;
    min-height: 0;
  }

  &.orbit-rings {
    background-image: url(../../images/hero_ring.svg);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    @media only screen and (min-width: 1440px) {
      background-size: 100%;
    }
    @media only screen and (max-width: 900px) {
      background-image: none;
    }
  }

  &__wrapper {
    text-align: center;
    position: relative;
    z-index: 1;
    position: relative;
    display: block;
    max-width: 1226px;
    width: 100%;
    margin: 0 auto;
    min-height: 800px;

    @media only screen and (max-width: 1440px) {
      min-height: 670px;
    }

    @media only screen and (max-width: 900px) {
      min-height: 0;
    }
  }

  .home-logo-counter-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    height: 100%;
    min-height: 750px;
    margin: 0 auto;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    padding: 188px 0;

    .hero-banner {
      position: absolute;
      right: 0;
      top: 188px;
      img {
        max-width: 746px;
        width: 100%;
      }
    }
    @media only screen and (max-width: 1200px) {
      padding-left: 16px;
      padding-right: 16px;
    }
    @media only screen and (max-width: 900px) {
      min-height: 0;
      max-width: 100vw;
      background-size: cover;
      justify-content: center;
      background-position: 50% -100px;
      flex-direction: column;
      padding: 0;
      .hero-banner {
        position: unset;
        order: 1;
        margin-top: 124px;
        img {
          max-width: 336px;
        }
      }
      .home-wrapper {
        order: 2;
      }
    }
  }

  .learn-realm {
    display: block;
    height: auto;
    left: 0%;
    position: absolute;
    top: 8%;
    width: 450px;

    @media only screen and (max-width: 1440px) {
      left: 10%;
      width: 290px;
      top: 140px;
    }

    @media only screen and (max-width: 900px) {
      display: none;
    }

    .text {
      position: absolute;
      top: -45px;
      left: -14px;
      z-index: 1;
      max-width: 200px;
    }
  }

  .dogood-realm {
    display: block;
    height: auto;
    position: absolute;
    right: 0%;
    top: 0%;
    width: 381px;

    @media only screen and (max-width: 1440px) {
      top: 80px;
      right: 9%;
      width: 290px;
    }

    @media only screen and (max-width: 900px) {
      display: none;
    }

    .text {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      max-width: 200px;
    }
  }

  .invest-realm {
    display: block;
    height: auto;
    left: 0%;
    position: absolute;
    bottom: -22%;
    width: 460px;
    transform: rotate(23deg);

    @media only screen and (max-width: 1440px) {
      top: 400px;
      left: 8%;
      width: 290px;
    }

    @media only screen and (max-width: 900px) {
      display: none;
    }

    .text {
      position: absolute;
      top: -66px;
      right: 90px;
      z-index: 1;
      max-width: 200px;
      transform: rotate(26deg);

      @media only screen and (max-width: 1440px) {
        right: -20px;
      }
    }
  }

  .play-realm {
    display: block;
    height: auto;
    position: absolute;
    right: 0%;
    bottom: 0%;
    width: 367px;

    @media only screen and (max-width: 1440px) {
      right: 10%;
      top: 400px;
      width: 290px;
    }

    @media only screen and (max-width: 900px) {
      display: none;
    }

    .text {
      position: absolute;
      top: -27px;
      left: -41px;
      z-index: 1;
      max-width: 200px;
      transform: rotate(-13deg);
    }
  }

  .home-wrapper {
    // less than menu
    z-index: 99;
    max-width: 534px;
    min-height: 559px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .home-logo {
      margin: 0 0 30px;
      max-width: 320px;
      width: 100%;
      display: block;

      @media only screen and (max-width: 900px) {
        display: none;
      }
    }
    .home-title {
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      color: #00d1ff;
      margin-bottom: 20px;
      text-align: start;
    }

    .home-countdown {
      align-items: flex-start;
      color: #fff;
      display: flex;
      flex-direction: row;
      font-family: 'Montserrat';
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      margin-bottom: 10px;
    }

    .home-moca-counter {
      box-sizing: border-box;
      margin: 0 auto 20px;
      padding: 0 20px;
      width: 300px;
      @media only screen and (max-width: 900px) {
        margin: 0 auto 17px;
      }

      .text {
        color: #fff;
      }

      .home-moca-counter-wrapper {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 10px 0 18px;
      }

      .counter {
        align-items: center;
        background-image: url(../../images/countdown.svg);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        color: #000;
        display: flex;
        flex-direction: column;
        font-family: 'Montserrat';
        font-size: 43px;
        font-style: normal;
        font-weight: 700;
        height: 89px;
        justify-content: center;
        line-height: 38px;
        margin: 0 2px;
        width: 75px;
      }
    }

    .btn.signup {
      width: 100px;
      margin: 30px auto 0;
      cursor: url(../../images/cursor.svg), auto;

      &:before {
        display: none;
      }
    }

    .btn-mint {
      width: 250px;
      margin: 0 auto 29px;
    }

    .btn-check-eligibility {
      width: auto;
      margin: 0 auto 29px;
    }
    @media only screen and (max-width: 900px) {
      margin: 27px 12px 0;
      min-height: unset;
      .home-title {
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        text-align: center;
        color: #00d1ff;
      }
    }
  }

  .signup-info {
    margin: 20px auto 65px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    padding: 5px 20px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    max-width: 410px;
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    text-align: center;
    justify-content: center;

    @media only screen and (max-width: 900px) {
      font-weight: 500;
      font-size: 14px;
      margin: 20px auto 25px;
    }

    &.eligible-success {
      margin: 20px auto 45px;
      max-width: 347px;

      @media only screen and (max-width: 900px) {
        margin: 20px auto 17px;
      }
    }

    &.error {
      color: var(--error-color);
    }
  }

  .socials-links-mobile {
    display: none;
    cursor: url(../../images/cursor.svg), auto;
    margin: 0 auto;

    @media only screen and (max-width: 900px) {
      display: block;
      margin-bottom: 12px;
    }

    ul {
      list-style: none;
      display: flex;
      flex-direction: row;
      margin: 0;
      justify-content: center;
    }

    li {
      padding: 0 8px;
    }

    a {
      display: block;
      width: 27px;
      @extend .social-animation;
      img {
        width: 100%;
      }
    }
  }

  .count-down {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #fff;
    margin: 0 0 20px;
    display: flex;

    @media only screen and (max-width: 900px) {
      margin: 0 0 22px;
    }

    &_item {
      display: flex;
      align-items: center;

      &:after {
        content: ':';
        margin: 0 4px;
      }

      &:last-child {
        &:after {
          content: '';
          margin: 0;
        }
      }
    }
  }

  &.comingsoon {
    .home-wrapper {
      @media only screen and (max-width: 900px) {
        margin-bottom: 27px;
      }
      .home-logo {
        max-width: 425px;

        @media only screen and (max-width: 900px) {
          max-width: 338x;
          margin: 130px auto 45px;
        }
      }
    }
  }

  .comingsoon {
    &-logo {
      width: 100%;
      max-width: 420px;
      margin: 0 0 65px;

      @media only screen and (max-width: 900px) {
        width: 284px;
        margin: 0 0 55px;
      }
    }

    &-info {
      background: rgba(0, 0, 0, 0.7);
      border-radius: 5px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #fff;
      width: 100%;
      max-width: 346px;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 20px;
      padding: 5px 20px;

      @media only screen and (max-width: 900px) {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  &.sold-out {
    .home-wrapper .home-logo {
      @media only screen and (max-width: 900px) {
        max-width: 338px;
        margin: 164px auto 38px;
      }
    }
  }
}

.intro-note {
  position: relative;
  width: 100%;
  max-width: 393px;
  margin: 0 auto;

  @media only screen and (max-width: 900px) {
    max-width: 288px;
  }

  &:hover {
    .intro-note-content {
      top: 65px;
      visibility: visible;

      @media only screen and (max-width: 900px) {
        top: 46px;
      }
    }
  }

  &-content {
    background: #9ddaff;
    position: absolute;
    width: 100%;
    min-height: 160px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding: 20px 10px;
    border-radius: 0 0 60px 0;
    top: 75px;
    left: 0;
    z-index: 99;
    visibility: hidden;
    transition: all 0.4s ease-in-out;

    @media only screen and (max-width: 900px) {
      font-size: 10px;
      line-height: 15px;
      padding: 6px 15px;
      min-height: 118px;
      top: 56px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 15px;
      top: -15px;
      left: 0;

      @media only screen and (max-width: 900px) {
        top: -11px;
        height: 11px;
      }
    }

    p {
      &:last-child {
        margin: 0;
      }
    }
  }

  &-arrow {
    display: block;
    width: 0;
    height: 0;
    border-left: 17px solid transparent;
    border-right: 17px solid transparent;
    border-bottom: 12px solid #9ddaff;
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);

    @media only screen and (max-width: 900px) {
      border-bottom-width: 8px;
      top: -8px;
    }
  }

  &-text {
    &,
    .intro-note-content &:last-child {
      margin: 20px 10px;
    }
  }
}

.btn-intro-note {
  font-family: 'Montserrat';
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  font-weight: 600;
  font-size: 18px;
  font-style: italic;
  line-height: 32px;
  text-decoration-line: underline;
  color: #fff;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  min-height: 50px;

  @media only screen and (max-width: 900px) {
    min-height: 35px;
    font-weight: 500;
    font-size: 14px;
    line-height: 8px;
  }

  &-icon {
    margin: 4px 7px 0 0;
    @media only screen and (max-width: 900px) {
      margin: 0 8px 0 0;
    }
  }
}

.eligible-not.errorWrap {
  max-width: 346px;
  margin: 20px auto 60px;

  @media only screen and (max-width: 900px) {
    margin: 20px auto;
  }
}

.sold-out {
  &-logo {
    margin: 0 0 18px;

    @media only screen and (max-width: 900px) {
      max-width: 325px;
      margin: 10px 0 7px;
      width: 100%;
    }
  }

  &-info {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #fff;
    width: 100%;
    word-break: break-word;
    text-align: start;
    p {
      margin-bottom: 30px;
      &.visit-marketplace {
        margin-bottom: 0;
      }
    }
    @media only screen and (max-width: 900px) {
      margin: 0 0 30px;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
    }
    a {
      color: #000;
      background: #ffcf00;
      box-shadow: 3px 4px 0px #000000;
      border-radius: 100px;
      border: 2px solid #000000;
      padding: 9px 18px;
      font-weight: bold;
      display: inline-flex;
      align-items: center;
      text-decoration: unset;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      &:hover {
        bottom: -3px;
        right: -3px;
        box-shadow: none;
        position: relative;
        background-color: #ffb800;
      }
      img {
        margin-right: 5px;
      }
    }
  }
}

.audio-player {
  height: 40px;
  width: 100px;
  position: fixed;
  color: white;
  bottom: 0px;
  left: 0px;

  .audio-player-control {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0;
    margin: 0;
  }
  & ul {
    width: 60px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    transition: ease 0.2s;
    position: relative;
  }

  & li {
    list-style: none;
    height: 35px;
    width: 4px;
    border-radius: 10px;
    background: white;
    margin: 0 3px;
    padding: 0;
  }

  & ul.playing li {
    animation-name: wave4;
    animation-duration: 0.3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transition: ease 0.2s;
  }

  & ul.stopped li {
    height: 10px;
  }

  & li:nth-child(2) {
    animation-name: wave2;
    animation-delay: 0.2s;
  }
  & li:nth-child(3) {
    animation-name: wave3;
    animation-delay: 0.23s;
    animation-duration: 0.4s;
  }
  & li:nth-child(4) {
    animation-name: wave4;
    animation-delay: 0.1s;
    animation-duration: 0.3s;
  }
  & li:nth-child(5) {
    animation-delay: 0.5s;
  }
  & li:nth-child(6) {
    animation-name: wave2;
    animation-duration: 0.5s;
  }
  & li:nth-child(8) {
    animation-name: wave4;
    animation-delay: 0.4s;
    animation-duration: 0.25s;
  }
  & li:nth-child(9) {
    animation-name: wave3;
    animation-delay: 0.15s;
  }
  & li:nth-child(10) {
    animation-delay: 0.5s;
  }
  & li:nth-child(11) {
    animation-name: wave2;
    animation-duration: 0.5s;
  }
  & li:nth-child(12) {
    animation-name: wave3;
    animation-delay: 0.4s;
    animation-duration: 0.25s;
  }
  li:nth-child(13) {
    animation-name: wave4;
    animation-delay: 0.15s;
  }
  & li:nth-child(14) {
    animation-name: wave4;
    animation-duration: 0.5s;
  }
  & li:nth-child(15) {
    animation-name: wave4;
    animation-delay: 0.1s;
    animation-duration: 0.5s;
  }

  @keyframes wave1 {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0.5);
    }
  }
  @keyframes wave2 {
    from {
      transform: scaleY(0.3);
    }
    to {
      transform: scaleY(0.6);
    }
  }
  @keyframes wave3 {
    from {
      transform: scaleY(0.6);
    }
    to {
      transform: scaleY(0.8);
    }
  }
  @keyframes wave4 {
    from {
      transform: scaleY(0.2);
    }
    to {
      transform: scaleY(0.5);
    }
  }
}

.audio-player {
  height: 40px;
  width: 100px;
  position: fixed;
  color: white;
  bottom: 0px;
  left: 0px;

  .audio-player-control {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0;
    margin: 0;
  }
  & ul {
    width: 60px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    transition: ease 0.2s;
    position: relative;
  }

  & li {
    list-style: none;
    height: 35px;
    width: 4px;
    border-radius: 10px;
    background: white;
    margin: 0 3px;
    padding: 0;
  }

  & ul.playing li {
    animation-name: wave4;
    animation-duration: 0.3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transition: ease 0.2s;
  }

  & ul.stopped li {
    height: 10px;
  }

  & li:nth-child(2) {
    animation-name: wave2;
    animation-delay: 0.2s;
  }
  & li:nth-child(3) {
    animation-name: wave3;
    animation-delay: 0.23s;
    animation-duration: 0.4s;
  }
  & li:nth-child(4) {
    animation-name: wave4;
    animation-delay: 0.1s;
    animation-duration: 0.3s;
  }
  & li:nth-child(5) {
    animation-delay: 0.5s;
  }
  & li:nth-child(6) {
    animation-name: wave2;
    animation-duration: 0.5s;
  }
  & li:nth-child(8) {
    animation-name: wave4;
    animation-delay: 0.4s;
    animation-duration: 0.25s;
  }
  & li:nth-child(9) {
    animation-name: wave3;
    animation-delay: 0.15s;
  }
  & li:nth-child(10) {
    animation-delay: 0.5s;
  }
  & li:nth-child(11) {
    animation-name: wave2;
    animation-duration: 0.5s;
  }
  & li:nth-child(12) {
    animation-name: wave3;
    animation-delay: 0.4s;
    animation-duration: 0.25s;
  }
  li:nth-child(13) {
    animation-name: wave4;
    animation-delay: 0.15s;
  }
  & li:nth-child(14) {
    animation-name: wave4;
    animation-duration: 0.5s;
  }
  & li:nth-child(15) {
    animation-name: wave4;
    animation-delay: 0.1s;
    animation-duration: 0.5s;
  }

  @keyframes wave1 {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0.5);
    }
  }
  @keyframes wave2 {
    from {
      transform: scaleY(0.3);
    }
    to {
      transform: scaleY(0.6);
    }
  }
  @keyframes wave3 {
    from {
      transform: scaleY(0.6);
    }
    to {
      transform: scaleY(0.8);
    }
  }
  @keyframes wave4 {
    from {
      transform: scaleY(0.2);
    }
    to {
      transform: scaleY(0.5);
    }
  }
}

.audio-player {
  height: 40px;
  width: 100px;
  position: fixed;
  color: white;
  bottom: 0px;
  left: 0px;

  .audio-player-control {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0;
    margin: 0;
  }
  & ul {
    width: 60px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    transition: ease 0.2s;
    position: relative;
  }

  & li {
    list-style: none;
    height: 35px;
    width: 4px;
    border-radius: 10px;
    background: white;
    margin: 0 3px;
    padding: 0;
  }

  & ul.playing li {
    animation-name: wave4;
    animation-duration: 0.3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transition: ease 0.2s;
  }

  & ul.stopped li {
    height: 10px;
  }

  & li:nth-child(2) {
    animation-name: wave2;
    animation-delay: 0.2s;
  }
  & li:nth-child(3) {
    animation-name: wave3;
    animation-delay: 0.23s;
    animation-duration: 0.4s;
  }
  & li:nth-child(4) {
    animation-name: wave4;
    animation-delay: 0.1s;
    animation-duration: 0.3s;
  }
  & li:nth-child(5) {
    animation-delay: 0.5s;
  }
  & li:nth-child(6) {
    animation-name: wave2;
    animation-duration: 0.5s;
  }
  & li:nth-child(8) {
    animation-name: wave4;
    animation-delay: 0.4s;
    animation-duration: 0.25s;
  }
  & li:nth-child(9) {
    animation-name: wave3;
    animation-delay: 0.15s;
  }
  & li:nth-child(10) {
    animation-delay: 0.5s;
  }
  & li:nth-child(11) {
    animation-name: wave2;
    animation-duration: 0.5s;
  }
  & li:nth-child(12) {
    animation-name: wave3;
    animation-delay: 0.4s;
    animation-duration: 0.25s;
  }
  li:nth-child(13) {
    animation-name: wave4;
    animation-delay: 0.15s;
  }
  & li:nth-child(14) {
    animation-name: wave4;
    animation-duration: 0.5s;
  }
  & li:nth-child(15) {
    animation-name: wave4;
    animation-delay: 0.1s;
    animation-duration: 0.5s;
  }

  @keyframes wave1 {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0.5);
    }
  }
  @keyframes wave2 {
    from {
      transform: scaleY(0.3);
    }
    to {
      transform: scaleY(0.6);
    }
  }
  @keyframes wave3 {
    from {
      transform: scaleY(0.6);
    }
    to {
      transform: scaleY(0.8);
    }
  }
  @keyframes wave4 {
    from {
      transform: scaleY(0.2);
    }
    to {
      transform: scaleY(0.5);
    }
  }
}
