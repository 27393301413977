// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&display=swap');
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'includes/reset';
@import 'includes/varibles';
@import 'includes/header';
@import 'includes/socials';
@import 'includes/intro';
// @import 'includes/introMobile';
@import 'includes/story';
// @import 'includes/storyMobile';
@import 'includes/realms';
// @import 'includes/realmsMobile';
@import 'includes/faq';
// @import 'includes/faqMobile';
@import 'includes/signup-modal';
@import 'includes/form';
@import 'includes/moca';
@import 'includes/packing';
@import 'includes/personality';
@import 'includes/completion';
@import 'includes/modal';
@import 'includes/packing-modal';
@import 'includes/realms-modal';
@import 'includes/reminding-modal';
@import 'includes/terms';
@import 'includes/licence';
@import 'includes/tv';
@import 'includes/what-is-mocaverse';
@import 'includes/wallet';
@import 'includes/navwrapper';
@import 'includes/utilities';
@import 'includes/mocana';
@import 'includes/mocana-realm-ticket';
@import 'includes/mocana-header';
@import 'includes/mocana-content-header';
@import 'includes/event';
@import 'includes/event-sidebar';
@import 'includes/icons';
@import 'includes/tooltip';
@import 'includes/user-board';
@import 'includes/tabsAndContentEvent';
@import 'includes/dustland';
@import 'includes/campaign';

* {
  box-sizing: border-box;
  text-decoration: none;
}

html {
  cursor: url(../images/cursor.svg), auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
  background-color: #0d1b2e;
}

body {
  cursor: url(../images/cursor.svg), auto;
  font-family: 'Montserrat';
  overflow-x: hidden;
  background-color: #0d1b2e;
  font-weight: 500;
}

button,
a {
  cursor: url(../images/cursor.svg), auto;
  font-family: 'Montserrat';
}

p {
  margin-bottom: 20px;
}

strong {
  font-weight: bolder;
}

ul,
ol {
  margin: 20px 0 20px 40px;
}

ol > li {
  list-style-type: decimal;
}

// .txt {
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   padding: 10px 20px;
//   gap: 10px;
//   background: #ffffff;
//   border: 4px solid #000000;
//   box-shadow: 10px 10px 0px #000000;
//   border-radius: 30px;
//   font-family: "Montserrat";
//   font-style: normal;
//   font-weight: 600;
//   font-size: 18px;
//   line-height: 32px;
//   color: #0a0a0a;
//   flex: none;
//   order: 0;
//   flex-grow: 0;

//   &.flat {
//     box-shadow: none;
//     border: 2px solid #000000;
//     line-height: 18px;
//   }

//   &.slim {
//     font-size: 12px;
//     line-height: 20px;
//     border: 2px solid #000000;
//     box-shadow: 3px 3px 0px #000000;
//     padding: 5px 10px;
//     margin-top: 5px;
//     align-items: center;
//   }
// }

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 40px;
  background: #ffcf00;
  border: 3px solid #000000;
  box-shadow: 3px 3px 0px #000000;
  border-radius: 100px;
  border: 2px solid #000000;
  height: 32px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
  text-decoration: none;

  @media only screen and (max-width: 900px) {
    padding: 10px 20px;
  }

  &:hover {
    bottom: -3px;
    right: -3px;
    box-shadow: none;
    position: relative;
    background-color: #ffb800;
  }

  &:before {
    content: '';
    background-image: url(../images/button-dot.svg);
    background-repeat: no-repeat;
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }

  &.error {
    background-color: #ff8888;
  }

  &.disable {
    background-color: #9d9d9d;
  }
}

.btn-md {
  border-width: 2px;
  box-shadow: 7px 9px 0px #000000;
  border-radius: 100px;
  height: 50px;
  min-width: 217px;

  @media only screen and (max-width: 900px) {
    height: 48px;
    min-width: 159px;
  }

  &::before {
    display: none;
  }

  &:disabled {
    background: #898989;
    color: #4d4d4d;
    cursor: default;

    &:hover {
      position: static;
      box-shadow: 7px 9px 0px #000000;
    }
  }
}

.btn-md-second {
  border-width: 2px;
  box-shadow: 7px 9px 0px #000000;
  border-radius: 100px;
  height: 50px;
  padding: 0 23px;

  @media only screen and (max-width: 900px) {
    height: 48px;
  }

  &::before {
    display: none;
  }

  &:disabled {
    background: #b8b5ac;
    color: #8f8f8f;
    cursor: default;

    &:hover {
      position: static;
      box-shadow: 7px 9px 0px #000000;
    }
  }
}

.btn-link {
  background: none;
  border: 0;
  padding: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  font-family: 'Montserrat';
  box-shadow: inset 0 -2px #fff;
  margin: 0 0 14px;

  @media only screen and (max-width: 900px) {
    margin: -3px 0 6px;
    box-shadow: inset 0 -1px #fff;
    line-height: 28px;
  }
}

.btn-icon {
  background: none;
  border: 0;
  padding: 0;
}

main {
  position: relative;
}

.bg {
  background-image: url(../images/background.jpg);
  background-size: cover;
  background-position: center center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.stars {
  background-image: url(../images/Stars_BG.svg);
  background-size: cover;
  background-position: center center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.svgimg {
  shape-rendering: geometricPrecision;
}
.disabledCursor {
  cursor: not-allowed !important;
  pointer-events: none !important;
}
.closeModal {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.closeModal button {
  position: absolute;
  top: -20px;
  right: -20px;
  border: none;
  background: transparent;
}
.closeModal p {
  display: block;
  max-width: 500px;
  max-height: 400px;
  overflow: auto;
}

:root {
  --error-color: #ff4141;
}

.errorWrap {
  padding: 15px 20px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  margin: 20px auto;
  word-break: break-all;
}

.errorLabel {
  color: var(--error-color) !important;
  display: inline-block;
  margin: 0 auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.txt-md {
  @media only screen and (max-width: 900px) {
    display: none;
  }
}

.txt-sm {
  @media only screen and (min-width: 901px) {
    display: none;
  }
}

.txt-no-spacing-sm {
  @media only screen and (max-width: 900px) {
    margin: 0;
  }
}

.page-loading {
  display: flex;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;

  .loader {
    width: 42px;
    height: 42px;
    border: 4px solid #2d3846;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.social-animation {
  img {
    transition: transform 0.4s ease-in-out;
  }

  &:hover {
    img {
      transform: scale(1.15);
    }
  }
}
