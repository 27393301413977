.mocana-wrapper {
  background: radial-gradient(
    51.66% 51.81% at 36.36% 41.18%,
    #3274c3 0.07%,
    #0e1e3f 100%
  );
  min-height: 100vh;
  select:focus,
  input:focus {
    outline: none;
  }

  .banner {
    width: 100%;
  }

  .moca-search-filter {
    display: flex;
    margin: 14px 0 12px 0;
  }
  .mocana-filter {
    position: relative;
    .mocana-button-sort {
      display: none;
    }
    .mocana-button-filter {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 10;
      margin-left: 1rem;
      button {
        font-weight: 700;
        font-size: 18px;
        line-height: 32px;
        color: #000000;
        background: #ffcf00;
        border: 2px solid #000000;
        box-shadow: 6px 6px 0px #000000;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 24px;
        &:hover {
          background-color: #ffb800;
          box-shadow: none;
          position: relative;
          bottom: -7px;
        }
      }
    }
    .mocana-list-filter,
    .mocana-list-sort {
      position: absolute;
      top: 110%;
      background: #0080ce;
      border: 1px solid;
      border-radius: 14px;
      z-index: 1;
      display: none;
      &.active {
        display: block;
      }
      .filter-wrapper,
      .sort-wrapper {
        overflow: hidden;
        border-radius: 14px;
      }
      .btn-apply-filter {
        text-align: center;
        margin: 6px auto 24px;
        font-weight: 700;
        font-size: 1rem;
        /* line-height: 32px; */
        color: #000000;
        background: #f8d748;
        border: 2px solid #000000;
        box-shadow: 3px 5px 0px #000000;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 32px;
      }
      .sub-filter-section {
        padding: 19px 17px;
        h3 {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          text-transform: uppercase;
          color: #ffffff;
          opacity: 0.8;
        }
        ul {
          margin: 10px 10px 10px 0;
          min-width: 220px;
        }
        li {
          margin: 12px 0;
          display: flex;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
          img {
            width: 20px;
            margin-top: -3px;
          }
          .custom-input {
            display: flex;
            position: relative;
            padding-left: 27px;
            margin-bottom: 0;
            cursor: pointer;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            &:hover input ~ .checkmark {
              background-color: #ccc;
            }
            &:after {
              content: '';
              position: absolute;
              display: none;
            }
            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 100%;
              width: 100%;
              left: 0;
              right: 0;
              z-index: 100;
              &:checked ~ .checkmark {
                background-color: #000000;
              }
              &:checked ~ .checkmark:after {
                display: block;
              }
            }
            .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 20px;
              width: 20px;
              background-color: #fff;
              border: 2px solid #000000;
              border-radius: 4px;
              &:after {
                content: '';
                display: none;
                position: absolute;
                left: 4px;
                top: 0px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }
          }
        }
      }
      h2 {
        background-color: #004975;
        border-bottom: 1px solid;
        padding: 8px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        .text-filter {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
        }
        .text-reset {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          text-decoration-line: underline;
          color: #ffffff;
        }
        button {
          border: 0;
          background: transparent;
          font-weight: bold;
          font-size: 0.9rem;
          text-decoration: underline;
        }
      }
      .filter {
        text-align: right;
        select {
          border: transparent;
          font-weight: 700;
          background: #f7aa17;
          padding: 10px 20px;
          &:focus {
            outline: none;
          }
        }
      }
      .toggle-button {
        display: none;
      }
    }
    .mocana-list-sort {
      display: none;
    }
  }
  .moca-search {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000;
    padding: 3px 16px;
    border-radius: 30px;
    width: 82%;
    max-width: 640px;
    background-color: #fff;
    position: relative;
    img {
      max-width: 30px;
    }
    button {
      border: 0;
      background: transparent;
      right: 6px;
      position: absolute;
    }
    input {
      width: 100%;
      font-size: 1rem;
      min-height: 34px;
      padding: 8px 0 8px 8px;
      background: transparent;
      border: transparent;
      font-family: 'Montserrat';
      font-weight: bold;
      &::placeholder {
        font-size: 1.1rem;
        color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  .moca-table {
    max-width: 1020px;
    margin: auto;
    padding: 12px;
    .table-container {
      width: 100%;

      &__header {
        &.active-moca_name {
          .table-container__header--col-2::after {
            transform: rotate(180deg);
          }
        }
        &.active-rank {
          .table-container__header--col-1::after {
            transform: rotate(180deg);
          }
        }
        &.active-tribe {
          .table-container__header--col-4::after {
            transform: rotate(180deg);
          }
        }
        display: flex;
        align-items: center;
        margin: 20px 0 8px 0;
        padding: 0 50px;
        justify-content: space-between;
        span {
          color: #fff;
          font-weight: 700;
          font-size: 16px;
        }
        &--col-1 {
          min-width: 98px;
        }
        &--col-2 {
          min-width: 150px;
        }
        &--col-4 {
          min-width: 150px;
        }
        &--col-5 {
          min-width: 150px;
        }
        &--col-6 {
          min-width: 150px;
          justify-content: end;
          left: 36px;
        }
        &--col-1,
        &--col-2,
        &--col-3,
        &--col-4,
        &--col-5,
        &--col-6 {
          display: flex;
          align-items: center;
          gap: 4px;
          position: relative;
          &:hover {
            .moca-table-tooltip {
              visibility: visible;
              opacity: 1;
              box-shadow: 6px 5px 0px #000000;
              &:before {
                content: '';
                position: absolute;
                top: 100%;
                left: 40%;
                margin-left: -5px;
                border-width: 25px;
                border-left-width: 15px;
                border-right-width: 15px;
                border-style: solid;
                border-color: #fff transparent transparent transparent;
                z-index: 10;
              }
              &:after {
                content: '';
                position: absolute;
                top: 100%;
                left: 39%;
                margin-left: -5px;
                border-width: 36px;
                border-left-width: 24px;
                border-right-width: 20px;
                border-style: solid;
                border-color: #000000 transparent transparent transparent;
                z-index: 1;
              }
            }
          }
        }
        &--col-1::after,
        &--col-2::after,
        &--col-3::after,
        &--col-4::after {
          content: '';
          display: block;
          width: 15px;
          height: 15px;
          background-image: url(../../images/arrow-sort.svg);
          background-position-y: -1px;
        }
        &--col-3::before,
        &--col-6::before {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          background-image: url(../../images/info.svg);
        }
      }

      &__item {
        display: flex;
        align-items: center;
        padding: 8px 50px;
        background: #fff9be;
        border: 1px solid #000000;
        border-radius: 12px;
        margin-bottom: 10px;
        background-repeat: no-repeat;
        background-image: url(../../images/item-table-background.svg);
        justify-content: space-between;
        &.mobile {
          display: none;
          background-image: unset;
          .table-container__item--col-4 {
            min-width: unset;
          }
          .table-container__item--col-2 {
            .link-opensea {
              img {
                max-width: 35px;
                max-height: 35px;
              }
            }
          }
        }
        &--col-1 {
          min-width: 101px;
        }
        &--col-2 {
          min-width: 150px;
        }
        &--col-4 {
          min-width: 150px;
        }
        &--col-5 {
          min-width: 150px;
        }
        &--col-6 {
          min-width: 150px;
          padding-right: 0;
        }
        &--col-1 {
          font-weight: 800;
          font-size: 24px;
          color: #333333;
        }
        &--col-2 {
          display: flex;
          align-items: center;
          gap: 8px;
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: 1px solid #545454;
          }
          span {
            font-weight: bold;
          }
          .link-opensea {
            img {
              max-width: 30px;
              max-height: 30px;
            }
          }
        }
        &--col-3 {
          font-weight: 700;
          font-size: 16px;
          color: #00cd3c;
          position: relative;
          &.not {
            color: red;
            div {
              color: #000;
            }
            span {
              display: flex;
              align-items: center;
            }
          }
          &:hover {
            .moca-table-tooltip {
              visibility: visible;
              opacity: 1;
              padding: 12px;
              p {
                font-size: 12px;
                font-weight: 500;
                margin: 3px 0;
                b {
                  font-weight: bold;
                }
              }
            }
          }
        }
        &--col-4 {
          font-weight: 700;
          font-size: 16px;
          color: #000000;
          text-transform: capitalize;
        }
        &--col-5 {
          font-weight: 500;
          font-size: 16px;
          // text-decoration-line: underline;
          color: #0080ce;
          display: flex;
          align-items: center;
          position: relative;
          .link-opensea {
            position: absolute;
            left: 92%;
            font-size: 0.9rem;
            font-weight: 700;
            color: #000000;
            background: #ffcf00;
            border: 2px solid #000000;
            box-shadow: 2px 3px 0px #000000;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 10px;
            text-decoration-line: none;
            img {
              max-width: 20px;
              margin-right: 3px;
            }
            &:hover {
              background-color: #ffb800;
              box-shadow: none;
              bottom: -12px;
            }
          }
          a {
            color: currentColor;
          }
        }
        &--col-6 {
          text-align: right;
          font-weight: 800;
          font-size: 28px;
          color: #000;
          // text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000,
          //       1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
        }
      }

      &__pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1.5rem auto;
        gap: 16px;
        &--prev,
        &--next {
          img {
            transform: rotate(90deg);
            width: 22px;
            margin-top: 3px;
          }
        }
        &--next img {
          transform: rotate(-90deg) !important;
        }
        select {
          font-family: inherit;
          font-size: 1.4rem;
          font-weight: 800;
          padding: 8px 28px;
          width: 100px;
          border-radius: 30px;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          background-image: url(../../images/arrow-down.svg);

          background-repeat: no-repeat;
          background-position: right 0.8em top 50%;
          background-size: 1em auto;
          box-shadow: 4px 4px 0px #000000;
          background-color: #f8d748;
        }
        &--select {
          font-family: inherit;
          font-size: 1.4rem;
          font-weight: 800;
          padding: 10px 28px;
          width: 110px;
          border-radius: 30px;
          -moz-appearance: none;
          -webkit-appearance: none;
          appearance: none;
          background-image: url(../../images/arrow-down.svg);
          background-repeat: no-repeat;
          background-position: right 0.8em top 50%;
          background-size: 1em auto;
          box-shadow: 4px 4px 0px #000000;
          background-color: #f8d748;
          position: relative;
          &--option {
            background: #f8d748;
            border: 1px solid #000000;
            box-shadow: 4px 4px 0px #000000;
            border-radius: 8px;
            position: absolute;
            width: 100%;
            bottom: 110%;
            left: 0;
            ul {
              margin: 0;
              padding: 0;
              max-height: 246px;
              overflow: scroll;
              &::-webkit-scrollbar {
                background: none;
                height: 6px;
                width: 7px;
              }
              &::-webkit-scrollbar-corner {
                background: none;
              }
              &::-webkit-scrollbar-thumb {
                background: rgba(136, 111, 85, 0.5);
                border-radius: 100px;
              }
              li {
                padding: 6px 16px;
                &:hover {
                  background: #ffa800;
                }
              }
            }
            .last-item {
              border-top: 1px solid rgba(0, 0, 0, 0.5);
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
              padding: 6px 16px;
              &:hover {
                background: #ffa800;
              }
            }
          }
        }

        &--label {
          color: #fff;
          font-weight: 600;
          font-size: 20px;
        }
      }
    }
    .moca-404 {
      padding: 5%;
      text-align: center;
      color: #fff;
      &.loading {
        min-height: 500px;
      }
      .loading-icon {
        max-width: 100px;
        margin-top: 2rem;
      }
      h3 {
        font-size: 20px;
        font-weight: bold;
        margin: 1rem 0;
      }
    }
    .moca-table-tooltip {
      max-width: 430px;
      position: absolute;
      background: white;
      z-index: 10;
      visibility: hidden;
      opacity: 0;
      bottom: 55px;
      left: 40%;
      transform: translateX(-50%);
      width: max-content;
      border: 2px solid #000000;
      border-radius: 10px;
      padding: 1rem;
      h2 {
        font-weight: bold;
        font-size: 1.3rem;
      }
      p {
        margin: 12px 0;
      }
      ul {
        list-style: disc;
        margin-left: 1.1rem;
        margin-bottom: 0;
        li:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
  }
  .moca-table-tooltip-modal {
    visibility: hidden;
  }
  .list-tabs.main-tabs-mocana {
    max-width: 1228px;
    margin: 32px auto 5px;
    @media only screen and (max-width: 1025px) {
      padding: 0 12.5px;
    }
    ul {
      margin-bottom: 0;
      @media only screen and (max-width: 991px) {
        justify-content: center;
      }

      li {
        button {
          display: flex;
          align-items: center;
          bottom: -5px;
          padding: 10px 12px;
          gap: 7px;
          @media only screen and (max-width: 991px) {
            padding: 3px 8px;
            bottom: -8px;
          }
          &.active {
            padding: 12px;
            bottom: -2px;
            @media only screen and (max-width: 991px) {
              bottom: -2px;
              padding: 6px 8px;
            }
          }
          img {
            width: auto;
            height: 28px;
            @media only screen and (max-width: 991px) {
              width: auto;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .mocana-wrapper {
    background: radial-gradient(
      127.07% 4.7% at 34.93% 24.84%,
      #3274c3 0%,
      #0e1e3f 100%
    );
    .moca-search-filter {
      flex-direction: column;
      .moca-search {
        width: 100%;
        padding: 6.5px 16px;
        img {
          width: 25px;
        }
        input {
          min-height: unset;
          padding: 0 0 0 6px;
          &::placeholder {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.3);
          }
        }
      }
      .mocana-filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
        .mocana-button-filter {
          img {
            opacity: 0.5;
            max-width: 16px;
          }
          button {
            padding: 0;
            box-shadow: none;
            border: 0;
            flex-direction: row-reverse;
            background: transparent;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
            > img {
              margin-left: 6px;
              filter: brightness(0) invert(1);
              opacity: 1;
            }
          }
        }
        .mocana-button-sort {
          display: block;
          button {
            border: 0;
            background: transparent;
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
            > img {
              margin-left: 6px;
              filter: brightness(0) invert(1);
            }
          }
        }
      }
      .mocana-list-filter,
      .mocana-list-sort {
        position: fixed;
        bottom: 0;
        top: unset;
        left: 0;
        width: 100%;
        max-height: 75%;
        z-index: 100;
        .filter-wrapper,
        .sort-wrapper {
          overflow: scroll;
        }
        h2 {
          justify-content: flex-start;
          .text-filter {
            order: 2;
            margin-left: 20%;
          }
          .text-reset {
            order: 1;
          }
        }
        .toggle-button {
          position: absolute;
          top: -28px;
          right: 21px;
          width: 55px;
          height: 55px;
          border-radius: 50%;
          border: 3px solid #000000;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f8d748;
        }
      }
      .mocana-list-sort {
        // display: block;
      }
    }
    .moca-results {
      .moca-404 {
        padding: 3% 0;
        text-align: center;
        .loading-icon {
          max-width: 60px;
        }
        img {
          max-width: 80px;
        }
        h3 {
          font-size: 18px;
        }
      }
      .table-container__header {
        display: none;
      }
      .table-container {
        &__item {
          display: none;
          &.mobile {
            display: block;
            padding: 0;
            position: relative;
          }
          &-top {
            display: flex;
            padding: 6px 14px;
            border-bottom: 1px solid;
            align-items: center;
            justify-content: space-between;
            background-repeat: no-repeat;
            background-image: url(../../images/item-table-background.svg);
          }
          &-bottom {
            padding: 6px 14px;
            display: flex;
            span.label {
              display: flex;
              align-items: center;
              font-size: 0.85rem;
              font-weight: bold;
              color: gray;
              font-family: 'Montserrat';
              margin-bottom: 4px;
            }
            &-right {
              .link-opensea {
                position: absolute;
                right: 14px;
                display: flex;
                align-items: center;
                top: 56px;
                font-weight: 700;
                font-size: 0.8rem;
                color: #000000;
                text-decoration: none;
                background: #ffcf00;
                border: 1px solid #000000;
                box-shadow: 1px 2px 0px #000000;
                border-radius: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 4px 10px;
                img {
                  width: 18px;
                  margin-right: 2px;
                }
                &:hover {
                  background-color: #ffb800;
                  box-shadow: none;
                  top: 58px;
                }
              }
            }
            &-right-flex {
              display: flex;
              margin-top: 18px;
            }
          }
          &--col-4 {
            width: auto;
          }
          &--col-2 {
            min-width: auto;
            flex-direction: column;
            font-weight: bold;
            width: fit-content;
            margin-right: 28px;
            img {
              width: 68px;
              height: 68px;
            }
          }
          &-own {
            margin-right: 28px;
          }
          &-eligibility,
          &-xp {
            .label::before {
              content: '';
              display: block;
              width: 13px;
              height: 13px;
              background-image: url(../../images/mocana/icon-tooltip-mb.svg);
              background-size: contain;
              margin-right: 2px;
            }
          }
        }
      }
    }

    .moca-table-tooltip-modal {
      visibility: visible;
      background-image: url(../../images/mocana/tooltip-modal.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      min-height: 314px;
      padding: 25px 45px;
      position: fixed;
      top: 25%;
      left: 25%;
      width: 100%;
      max-width: 349px;
      z-index: 100;
      .toggle-button {
        position: absolute;
        top: -3px;
        right: -1px;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        border: 3px solid #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f8d748;
      }
      .tooltip-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        margin-bottom: 9px;
      }
      .tooltip-description {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 0;
        a {
          text-decoration: none;
          color: #000;
        }
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .mocana-wrapper {
    .moca-table-tooltip-modal {
      left: calc((100vw - 350px) / 2) !important;
    }
  }
}

.content-header-note {
  color: #fff;
  padding: 14px 9px;
  margin: 0;
  &.realm {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding-top: 0;
    padding-left: 0;
  }

  @media only screen and (max-width: 991px) {
    font-size: 14px;
    margin: 0 0 11px;
    text-align: center;
    line-height: 18px;
  }
}
