header {
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999999;

  nav {
    position: relative;
    .desktop {
      background-color: #00d1ff;
      border-bottom-left-radius: 100px;
      border-bottom-right-radius: 100px;
      width: 100%;
      max-width: 935px;
      height: 88px;
      padding: 0 60px;
      box-sizing: border-box;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin: 0 auto;
      @media only screen and (max-width: 900px) {
        display: none;
      }

      li:not(:first-child):hover {
        a {
          transform: scale(1.05);
        }
      }
    }

    .navBg {
      display: none;
      border-bottom: 3px solid black;
      @media only screen and (max-width: 900px) {
        background-color: #00d1ff;
        width: 100%;
        display: flex;
        max-width: 970px;
        height: 64px;
        padding: 0 30px;
        box-sizing: border-box;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        z-index: -1;
      }
    }

    .mobile {
      display: none;
      @media only screen and (max-width: 900px) {
        top: 0;
        width: 100%;
        display: flex;
        max-width: 970px;
        height: 64px;
        padding: 0 15px;
        box-sizing: border-box;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: fixed;
        z-index: 9000;

        .connect.btn a {
          font-size: 1rem;
        }
        &.close {
          .mobile-nav {
            display: none;
          }
        }

        &.open {
          .mobile-nav {
            display: flex;
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            z-index: -2;
            flex-direction: column;
            top: 64px;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;

            .content {
              border: 2px solid black;
            }

            ul {
              margin: 0;
              padding: 0;
            }

            li {
              padding: 20px;
              box-sizing: border-box;
              border-top: 2px solid #000;
              background-color: white;

              &:first-child {
                border-top: none;
              }

              &.last {
                background-color: #fff;
                position: relative;
                z-index: 3;
                border-bottom-left-radius: 30px;
                border-bottom-right-radius: 30px;
                border-bottom: 2px solid black;
              }

              .icon {
                background-image: url(../../images/moca-home.png);
                background-size: contain;
                width: 25px;
                height: 25px;
                display: block;
                margin-right: 10px;
              }

              a {
                display: flex;
                flex-direction: revert;
                justify-content: center;
                align-items: center;
              }
            }

            &.show {
              display: flex;

              &:before {
                height: 100vh;
                width: 100vw;
                background-color: #000;
              }
            }

            .social-btn {
              flex-direction: row;
              justify-content: center;
              align-items: center;
              background-color: #ffcf00;
              border-bottom-left-radius: 30px;
              border-bottom-right-radius: 30px;
              padding: 20px 10px 5px;
              box-sizing: border-box;
              position: relative;
              top: -25px;
              border: 2px solid #000;

              img {
                max-width: 27px;
              }

              ul {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 0;
                margin: 0;

                li {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  cursor: url(../../images/cursor.svg), auto;
                  width: 50px;
                  height: 54px;
                  border: 0;
                  padding: 12px 10px 10px;
                  background-color: transparent;
                }
              }

              a {
                width: 28px;
                height: 28px;
              }
            }

            .mobile-sub-items {
              background-color: white;
              position: relative;
              padding-bottom: 8px;
              margin-top: -12px;
              height: 0;
              opacity: 0;
              max-height: 0;
              visibility: hidden;
              transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;

              a {
                display: block;
                width: fit-content;
                padding: 10px 4px;
                margin-left: 16px;
                font-size: 16px;
                line-height: 20px;
                font-weight: 700;
              }

              &.show {
                height: auto;
                max-height: 500px;
                opacity: 1;
                visibility: visible;
              }
            }
          }

          .navoverlay {
            position: absolute;
            display: block;
            left: 0;
            right: 0;
            bottom: 0;
            top: 91px;
            height: 100vh;
            width: 100vw;
            background: black;
            opacity: 0.4;
            z-index: -3;
          }
          .mobile-moca-icon {
            width: 25px;
            margin: 0 10px 0 30px;
            &.nav-user-board {
              width: 120px;
              border: 0;
              margin: 0 10px 0 35px;
              border-radius: 0;
            }
            @media only screen and (max-width: 900px) {
              margin: 0 5px 0 32px;
            }
          }
          .mobile-nav.nav-user-board {
            ul {
              li.home {
                display: none;
              }
            }
          }
        }
      }
      .mobile-nav .social-btn a {
        @extend .social-animation;
      }

      .mobile-moca-icon {
        width: 25px;
        &.nav-user-board {
          width: 120px;
          border: 0;
          margin: 0 10px 0 30px;
        }
        @media only screen and (max-width: 900px) {
          margin: 0 5px 0 32px;
        }
      }

      span {
        width: 113px;
        height: 24px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-transform: capitalize;
        color: #222222;
      }

      .mobile-btn {
        background-image: url(../../images/mobile-menu-btn.svg);
        background-position: left;
        background-repeat: no-repeat;
        width: 300px;
        height: 25px;
        display: flex;

        &.open {
          background-image: url(../../images/mobile-menu-btn-close.svg);
        }

        &.close {
          background-image: url(../../images/mobile-menu-btn.svg);
        }
      }
    }

    li {
      list-style: none;
      display: flex;
      flex-direction: row;
      &.last {
        z-index: 10;
      }

      .utility {
        opacity: 0.4;
        margin-right: 5px;
        font-family: 'montserrat', sans-serif;
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
      }

      span.soon {
        opacity: 1;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 3px 8px;
        gap: 10px;
        width: 49px;
        height: 21px;
        background: #0080ce;
        border: 3px solid #000000;
        border-radius: 100px;
        flex: none;
        order: 1;
        flex-grow: 0;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        flex: none;
        order: 0;
        flex-grow: 0;
      }

      .disconnect {
        font-family: 'montserrat', sans-serif;
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        cursor: url(../../images/cursor.png), auto;
        text-decoration: none;
        color: #005284;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: row;

        &:before {
          content: '';
          background: url(../../images/disconnect.png);
          background-repeat: no-repeat;
          display: block;
          height: 24px;
          width: 24px;
          margin-right: 10px;
        }
      }
    }

    a,
    .link {
      font-family: 'montserrat', sans-serif;
      font-weight: 800;
      font-size: 18px;
      line-height: 22px;
      cursor: url(../../images/cursor.png), auto;
      text-decoration: none;
      color: #000;
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      gap: 4px;

      &:before {
        display: none;
      }

      &:hover {
        &:before {
          content: '';
          display: block;
          height: 8px;
          width: 100%;
          background-color: #00d1ff;
          position: absolute;
          z-index: -1;
          top: 25px;
          @media only screen and (max-width: 900px) {
            top: 30px;
          }
        }
      }

      &.active {
        &:before {
          content: '';
          display: block;
          height: 6px;
          width: 100%;
          background-color: #f8d748;
          position: absolute;
          z-index: -1;
          top: 15px;
        }
      }
    }

    .home {
      &:hover {
        &:before {
          display: none;
        }
      }
      img {
        width: 54px;
      }
    }
  }

  .nav-desktop {
    transition: top 0.6s ease-in-out;
    display: flex;
    z-index: 1100;
    margin: 0 auto;
    left: 0;
    right: 0;
    position: fixed;
  }

  .home-menu {
    position: relative;

    .menu-items {
      display: none;
      position: absolute;
      width: 250px;
      top: 30px;
      left: -10px;
      background: #b9f2ff;
      border-radius: 10px;
      padding: 20px 16px;
      border: 2px solid black;
      box-shadow: 3px 3px 0px black;
      z-index: 1;
      margin-top: 10px;
      opacity: 0.5;

      a {
        display: inline-block;
        width: fit-content;
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        transform: scale(1) !important;
      }
    }

    &:hover {
      .menu-items {
        display: flex;
        gap: 20px;
        flex-direction: column;
        opacity: 1;
        transition: opacity 1s ease-in-out;
      }
    }
  }

  .menu-shrink-desktop {
    background: url(../../images/navbar_mini.svg);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    margin: 0 auto;
    max-width: 970px;
    height: 89px;
    padding: 0 120px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    position: fixed;
    top: -2px;
    left: 0;
    right: 0;
    z-index: 1010;

    @media only screen and (max-width: 900px) {
      display: none;
    }
  }
}

.connect-select {
  position: relative;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;

  &-title {
    background: #ffcf00;
    border: 2px solid #000000;
    box-shadow: 3px 3px 0px #000000;
    border-radius: 100px;
    min-height: 52px;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 5px 18px;

    &:hover {
      box-shadow: none;
    }

    @media only screen and (max-width: 900px) {
      min-height: 40px;
      width: auto !important;
      padding: 5px 10px;
    }

    &::after {
      content: '';
      background: url('../../images/arrow-down.png') 0 0 no-repeat;
      background-size: contain;
      width: 23px;
      height: 22px;
      margin: 0 0 0 10px;
      transition: transform 0.4s ease-in-out;

      @media only screen and (max-width: 900px) {
        margin: 0 0 0 5px;
      }

      .connect-select.open & {
        transform: rotate(180deg);
      }
    }
  }

  &-content {
    background: #ffcf00;
    border: 2px solid #000000;
    box-shadow: 3px 3px 0px #000000;
    border-radius: 19px;
    width: 320px;
    position: absolute;
    top: 62px;
    right: 0;
    display: none;

    .connect-select.open & {
      display: block;
    }

    @media only screen and (max-width: 900px) {
      top: 46px;
    }

    @media only screen and (max-width: 360px) {
      width: 253px;
    }

    ul {
      margin: 0;
      padding: 17px 22px;

      li {
        margin: 0 0 17px;

        &:last-child {
          margin: 0;
        }
        > a:hover {
          &::before {
            display: none;
          }
        }
      }
    }
  }

  .btn-icon {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    font-family: 'Montserrat';
    display: flex;
    align-items: center;
    width: 100%;

    &:hover {
      &:before {
        transform: scale(1.1);
      }
    }

    &.btn-account:before {
      width: 25.5px;
      height: 25.5px;
      background-size: contain;
      background: url('../../images/user.svg') 0 0 no-repeat;
    }
    &:before {
      content: '';
      background: url('../../images/copy.png') 0 0 no-repeat;
      background-size: contain;
      width: 22px;
      height: 22px;
      margin: 0 8px 0 0;
    }
  }

  .btn-update {
    margin: 0 -4px 0 -10px;
    background: #ffffff;
    border: 2px solid #000000;
    box-shadow: 3px 3px 0px #000000;
    border-radius: 100px;
    height: 50px;
    width: calc(100% + 14px);

    &:hover {
      background: #fff;
    }

    &:before {
      display: none;
    }
  }

  .btn-change-wallet {
    &:before {
      background: url('../../images/changewallet.svg') -3px 0 no-repeat;
      width: 24px;
      height: 24px;
      margin: 0 8px 0 0;
    }
  }

  .btn-disconnect {
    &:before {
      background: url('../../images/disconnect-icon.svg') 0 0 no-repeat;
      margin: 0 8px 0 0;
    }
  }

  .wallet-copied {
    color: #008126;
  }
}

.connect-name {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #000;
  padding: 2px 22px;

  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #00cd3c;
    border: 2px solid #000;
    border-radius: 14px;
    margin: 0 7px 0 0;
  }
}

.connect-wrapper {
  @media only screen and (max-width: 900px) {
    position: absolute;
    right: 15px;
    top: 11px;
  }

  .connect {
    &.btn {
      height: 52px;
      box-sizing: border-box;
      overflow: hidden;
      padding: 20px;
      width: auto;
      position: relative;
      left: 20px;
      a:hover::before {
        display: none;
      }

      @media only screen and (max-width: 900px) {
        left: auto;
        padding: 10px 0;
        position: absolute;
        right: 0;
        top: 1px;
        width: 140px;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        height: 40px;
        background: #f8d748;
      }

      &:before {
        display: none;
      }
    }
  }
}
.mocana-header {
  nav {
    position: sticky;
  }
  .menu {
    display: flex;
    max-width: 935px;
    margin: 0 auto;
    align-items: center;

    .menu-item {
      padding: 16px 20px;
      margin: 0 2px;
      color: black;
      font-weight: 700;
      font-size: 1.2rem;
      a {
        &:hover:before {
          display: none;
        }
      }
      &.connect {
        a {
          background: #f47474;
          padding: 8px 16px;
          &:hover:before {
            display: none;
          }
        }
      }

      &:hover {
        background: #d9d9d9;
      }

      &.dropdown {
        position: relative;

        .dropdown-content {
          display: none;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          background: #d9d9d9;
          width: max-content;
          text-align: center;
          padding: 12px;

          .dropdown-item {
            padding: 10px;
            font-size: 1rem;

            &:hover,
            &:hover a,
            &:active {
              color: #f47474;
            }
          }
        }

        &:hover .dropdown-content {
          display: block;
        }
      }
    }
  }
}

.nav-dashboard {
  header nav & {
    max-width: 978px;

    &.desktop {
      border: 2px solid #000000;
      border-top: 0;
    }

    .home img {
      width: 224px;
    }
  }

  @media only screen and (max-width: 900px) {
    header nav .mobile.open & {
      height: auto;
      border-radius: 0 0 25px 25px;
      border: 2px solid #000;
      border-top: 0;
      margin: 0 -2px;
      width: calc(100% + 4px);

      .social-btn {
        margin-bottom: -32px;
      }
    }
  }
}

.connect-select-content {
  .connect-dashboard {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 0 0 28px;

    a,
    .link {
      font-weight: 700;
      font-size: 18px;
      line-height: 32px;
    }

    &-items {
      padding: 5px 0 0 0;
    }

    &-item {
      margin: 0 0 6px;

      a,
      .link {
        font-weight: 500;
      }
    }
  }
}

.nav-group {
  position: relative;

  &:hover {
    .nav-group-items {
      display: block;
    }
    .nav-group-link:after {
      display: block;
    }
  }

  &-items {
    display: none;
    position: absolute;
    background: #ffffff;
    border: 2px solid #000000;
    box-shadow: 3px 3px 0px #000000;
    border-radius: 12px;
    overflow: hidden;
    width: 270px;
    right: 0;
    top: 35px;
    z-index: 1;

    @media only screen and (max-width: 900px) {
      position: relative;
      margin: 24px -21px 1px;
      width: calc(100% + 46px);
      box-shadow: none;
      padding: 0;
      background: #fff;
      top: 0;
      left: 0;
      border-radius: 0 0 25px 25px;
      overflow: visible;
      border: 0;
      border-bottom: 0;
      flex-wrap: wrap;
      display: none;
    }
  }

  &-link {
    position: relative;

    &:after {
      content: '';
      width: 100%;
      height: 16px;
      position: absolute;
      bottom: -14px;
      right: 0;
      display: none;
    }
    &-icon {
      margin: 0 0 0 7px;
      display: inline-flex;
    }
  }
  &-item {
    @media only screen and (max-width: 900px) {
      width: 100%;
    }

    &:first-child {
      @media only screen and (max-width: 900px) {
      }
      .nav-group-item-label {
        border-top: 0;
      }
    }
    &-label {
      background: transparent;
      border-top: 2px solid #000;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #1c4d69;
      opacity: 0.75;
      padding: 12px 15px;
      padding-bottom: 0;

      @media only screen and (max-width: 900px) {
        padding: 2px 20px;
        background: none;
        font-size: 16px;
        color: #0080ce;
        border: 0;
        min-height: 0;
      }
    }
  }

  &-sub-items {
    margin: 8px 0 21px;

    header nav .mobile.open .mobile-nav & {
      margin: 0 0 24px;
    }

    header nav .mobile.open .nav-group-item:last-child & {
      margin: 0;
    }

    li {
      padding: 4px 0 4px 16px;

      .user-board header nav .mobile.open li &,
      .mocana-page header nav .mobile.open li &,
      .user-board header nav .mobile li &,
      .mocana-page header nav .mobile li & {
        border: 0;
        padding: 5px 20px;
      }
      &:hover {
        background: #e4eff1;
      }
    }

    header nav .desktop li:not(:first-child):hover & a {
      transform: scale(1);
    }
    header nav & a,
    a {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      display: flex;
      position: relative;
      padding: 0 20px 0 0;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      &:hover {
        transform: scale(1.03) !important;

        @media only screen and (max-width: 900px) {
          transform: scale(1) !important;
          width: auto;
          &::before {
            display: block !important;
          }
        }

        &::before {
          display: none;
        }

        .nav-group-sub-item-icon {
          right: -10px;
        }
      }
    }
  }
  &-item-experience {
    padding: 10px 0 13px;
    display: flex;
    justify-content: center;
    border-top: 1px solid #000000;
    &:hover {
      background: #e4eff1;
    }
    a {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #0080ce;
      &:hover {
        text-decoration: none;
        &:before {
          display: none;
        }
      }
    }
  }

  &-sub-item {
    &-icon {
      margin: 0 0 0 5px;
      display: inline-flex;
    }
  }
}

.mocana-page,
.campaign-page,
.user-board {
  header nav .mobile {
    .mobile-btn {
      height: 32px;
    }
    .mobile-moca-icon {
      width: 120px;
      height: 29px;
      margin: 0 10px 0 35px;
      border-radius: 0;
      border: 0;
    }

    li {
      flex-wrap: wrap;
    }

    &.open {
      .mobile-nav {
        li {
          padding: 22px 20px;

          &.home {
            display: none;
          }

          &.nav-group {
            padding: 20px;
            position: relative;
            border-bottom: 3px solid #000;
            box-shadow: 1px 1px 0px #000;

            .nav-group-link:hover {
              .nav-group-link-icon {
                transform: rotate(180deg);
              }
            }
            .nav-group-link:hover ~,
            &.show {
              .nav-group-link-icon {
                transform: rotate(180deg);
              }
              .nav-group-items {
                display: block;
              }
            }
          }

          a {
            justify-content: flex-start;
          }
        }
        .social-btn {
          border-radius: 0 0 25px 25px;
        }
      }
    }
    span {
      &.nav-group-link-icon {
        width: 18px;
        height: 10px;
      }
      &.nav-group-sub-item-icon {
        width: 10px;
        height: 17px;
        margin: 0 0 0 7px;

        @media only screen and (max-width: 900px) {
          display: none;
        }
      }
    }
  }
}
